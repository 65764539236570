/* type */
@import 'colors';

.fw-bd {
  font-weight: bold;
}
.fw-500 {
  font-weight: 500;
}
.title {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}

h1,
h2,
h3,
h4 {
  color: $primary-dark;
}

h1 {
  font-size: 42px;
  line-height: 60px;
}

h2 {
  font-size: 38px;
  line-height: 56px;
}

h3 {
  font-size: 30px;
  line-height: 38px;
}

h4 {
  font-size: 24px;
  line-height: 34px;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 46px;
  }

  h2 {
    font-size: 28px;
    line-height: 56px;
  }

  h3 {
    font-size: 21px;
    line-height: 30px;
  }

  h4 {
    font-size: 20px;
    line-height: 30px;
  }
}
