@import '~sass/colors';

.cal-event {
  border-color: transparent !important;
}

.cal-week-view {
  .cal-time-label-column .cal-hour {
    &:first-child,
    &:nth-child(n + 13) {
      .cal-hour-segment::after {
        display: flex;
        justify-content: center;
        content: '\f186';
        font-family: 'Font Awesome\ 5 Pro';
        font-weight: bold;

        padding-top: 5px;
        font-size: 20px;
      }
    }
  }

  .cal-time-events {
    height: 50vh;
    overflow: auto;
    margin-right: -9px;
  }

  .cal-day-column {
    border: none;
  }

  .cal-hour {
    border-left: 1px solid $cal-border;
  }

  .cal-header {
    b,
    span {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      color: $cal-black;
    }
  }

  .cal-header.cal-weekend span {
    color: $grey-medium;
  }

  .cal-hour-odd {
    background-color: transparent;
  }

  .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: 1px solid;
    border-color: $grey-lightest;
  }

  .cal-time {
    position: relative;
    color: $grey-medium;
    background: $white;
  }

  .cal-today {
    background: $brand-primary !important;
    color: $white;

    b {
      color: $white !important;
      text-transform: uppercase;
      font-size: 10px;
    }

    span {
      color: $white !important;
      font-weight: bold;
      opacity: 1;
      text-transform: uppercase;
      font-size: 10px;
    }
  }

  .cal-event {
    font-size: 10px;
  }
}

.default-calendar {
  .cal-month-view {
    .cal-header {
      background-color: $white;

      .cal-cell {
        font-size: 10px;
        font-weight: bold;
        line-height: 2.4;
        text-align: left;
        color: $cal-black;

        padding-left: 10px;

        border-bottom: 0;
        border-top: 1px solid $cal-border;
        border-right: 1px solid $cal-border;

        &:first-child {
          border-left: 1px solid $cal-border;
        }
        &.cal-today {
          background-color: $white !important;
        }
      }
    }

    .cal-day-cell {
      min-height: 114px;

      &.cal-today {
        background-color: $white !important;
        .cal-day-number {
          background-color: $brand-primary;
          color: $white;
          font-size: 12px;
        }
      }
      &.cal-weekend {
        .cal-day-number {
          color: $cal-black;
        }
      }
      &.cal-in-month.cal-has-events {
        cursor: default;
      }
    }

    .cal-cell-top {
      min-height: 26px;
      height: 26px;
      flex: unset;
      text-align: right;
    }

    .cal-day-badge {
      display: none;
    }

    .cal-day-number {
      display: inline-block;
      font-size: 12px;
      font-weight: bold;
      float: none;
      opacity: 1;
      margin-top: 1px;
      margin-right: 1px;
      padding: 3px 3px 1px 3px;
      color: $cal-black;
    }

    .cal-day-selected,
    .cal-day-selected:hover {
      background-color: $primary-dark !important;
      .cal-day-number {
        color: $white !important;
      }
    }

    .cal-events {
      margin: 0;
      border-bottom: solid 0.2px $grey-979797;

      background-color: $cal-theory-event;
      color: $white;

      cursor: pointer;

      &.first-event {
        border-top: solid 0.2px $grey-979797;
      }

      &.show-more-event {
        border-bottom: none;
        background-color: unset;
        color: unset;
      }

      .showmore {
        width: 100%;
        padding-left: 4px;
        line-height: 0.3;
      }

      &.practice {
        background: repeating-linear-gradient(
          45deg,
          $white 5px,
          $cal-practice-unpaid-color1 5px,
          $cal-practice-unpaid-color2 7px,
          $cal-practice-unpaid-color3 8px
        );
        color: $white;
      }

      &.practice.fully-completed-event {
        background: $cal-practice-green;
      }

      &.practice.free-slot {
        background: $cal-practice-free-slot-color1
          repeating-linear-gradient(
            45deg,
            $white 5px,
            $white 5px,
            $cal-practice-free-slot-color1 7px,
            $cal-practice-free-slot-color1 8px
          );
      }

      &.practice.waiting-for-instructor-confirmation-slot {
        background: repeating-linear-gradient(
          45deg,
          $white 5px,
          $white 5px,
          $cal-legends-requests-color1 7px,
          $cal-legends-requests-color2 8px
        );

        * {
          color: $grey-medium;
        }
      }

      &.practice.waiting-for-learner-confirmation-slot {
        background: $cal-waiting-for-learner-bg !important;
        color: $grey-medium !important;
      }

      &.practice.cancelled-slot {
        background: repeating-linear-gradient(
          45deg,
          $white 5px,
          $cal-practice-exams-bg 5px,
          lighten($cal-practice-exams-bg, 18%) 7px,
          $cal-practice-exams-bg 8px
        ) !important;
        color: $white !important;
      }

      &.practice.not-occurred-slot {
        background: repeating-linear-gradient(
          45deg,
          $white 5px,
          $cal-not-occurred-bg 5px,
          lighten($cal-not-occurred-bg, 18%) 7px,
          $cal-not-occurred-bg 8px
        ) !important;
        color: $white !important;
      }

      &.practice.practice-exam-slot {
        background: $cal-exam-bg !important;
        color: $white !important;

        &.waiting-for-learner-confirmation-slot {
          background: repeating-linear-gradient(
            45deg,
            $white 5px,
            $cal-exam-bg 5px,
            lighten($cal-exam-bg, 18%) 7px,
            $cal-exam-bg 8px
          ) !important;
          color: $white !important;
        }
      }

      &.block_time {
        background: repeating-linear-gradient(
          45deg,
          $cal-block-time-color1 5px,
          $cal-block-time-color2 5px,
          $cal-block-time-color3 7px,
          $cal-block-time-color4 8px
        );
        color: $grey-dark;
      }
    }

    .month-event-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 9px;
      padding: 4px;

      .event-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
        flex: 1;
      }

      .event-time {
        font-size: 8px;
      }
    }
  }
}

.day-desktop-container {
  .cal-day-view {
    .cal-hour-rows {
      height: 50vh;
      border: none;
    }
  }
}

.day-view {
  .cal-hour-rows {
    max-height: calc(100vh - 50px) !important;
  }

  .today-date {
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: 0 1px 5px 0 $cal-control-shadow;
    background-color: $white;
    min-height: 65px;
    padding: 20px 15px 8px;
    font-weight: bold;
    color: $primary-dark;
    font-size: 20px;
    position: sticky;

    .today-day {
      opacity: 0.5;
      font-size: 16px;
    }
  }

  .cal-week-view .cal-time {
    height: 100%;
  }
}

.desktop-day-view {
  .today-date-banner {
    border: 1px solid $cal-border;
    border-bottom: 0;

    .today-date-desktop-text {
      font-size: 14px;
      font-weight: bold;
      color: $primary-dark;
    }
  }
  .cal-week-view .cal-time {
    height: 100%;
    border-right: 1px solid $cal-border;
  }
}

.desktop-day-view,
.day-view {
  .cal-day-column .cal-hour {
    &:nth-child(3),
    &:nth-child(n + 15) {
      .cal-time::after {
        display: flex;
        justify-content: center;
        content: '\f186';
        font-family: 'Font Awesome\ 5 Pro';
        font-weight: bold;

        padding-top: 5px;
        font-size: 20px;
      }
    }
  }
}

.cal-day-controls {
  .add-dropdown {
    .dropdown {
      .dropdown-handler {
        .dropdown-text {
          margin: 0 auto;
        }

        .dropdown-arrow {
          position: absolute;
          right: 0;
          height: 100%;
          width: 24px;
        }
      }
    }
  }
}

.cal-event-container {
  border-color: transparent !important;
  margin-left: 1px;
  max-width: calc(100% - 1px) !important;
  transition: 0.3s;

  &:hover {
    width: 100% !important;
    z-index: 2 !important;
    transition: 0.3s;
  }

  .cal-event {
    color: $white !important;
    display: flex;
    align-items: center;
    position: relative;
    line-height: 22px !important;

    i {
      margin-right: 5px;
    }

    mwl-calendar-event-title {
      width: calc(100% - 20px);

      .cal-event-title {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .lesson-topic-key {
        font-weight: bold;
      }
    }
  }

  &.theory {
    .theory-time-block-container {
      display: flex;
      flex-wrap: wrap;
      min-width: 90%;
      width: 90%;
      position: absolute;
      top: 0;
    }

    .theory-time-block-key {
      flex: 100%;
      font-weight: bold;
    }

    .theory-time-block-attendees {
      font-size: 10px;
      font-weight: bold;
      flex: 100%;
      position: relative;
      top: 5px;
    }

    .theory-time-block-simple-label {
      flex: 100%;

      &.instructor-name {
        line-height: 1.77;
      }
    }

    .theory-time-block-progress {
      flex: 100%;

      .progress {
        background-color: $white;
        border-radius: 5px;
        position: relative;
        height: 3px;
        top: 10px;

        .progress-bar {
          &.blue {
            background-color: $cal-theory-progress-bar;
          }
          &.green {
            background-color: $progress-bar-1;
          }
        }
      }

      mat-progress-bar {
        border-radius: 5px;
        position: relative;
        top: 10px;

        .mat-progress-bar-buffer {
          background-color: $white;
        }

        .mat-progress-bar-fill::after {
          background-color: $cal-theory-progress-bar;
        }
      }
    }

    .cal-event {
      border: 0 solid;
      background-color: $cal-theory-event;
      border-radius: 0;
      width: 100%;
    }
  }

  &.practice {
    &:hover {
      min-height: 98px !important;
    }

    background: repeating-linear-gradient(
      45deg,
      $white 5px,
      $cal-practice-unpaid-color1 5px,
      $cal-practice-unpaid-color2 7px,
      $cal-practice-unpaid-color3 8px
    );

    .filler {
      flex-grow: 1;
    }

    .practice-time-block-container {
      height: 100%;
      width: 100%;
      min-width: 100%;
      display: flex;
      flex-direction: column;

      .topic-container {
        color: $white;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .learner-name {
        color: $white;
        font-size: 9px;
        line-height: 6px;
        margin-bottom: 9px;
      }
    }

    .cal-event {
      background-color: transparent;
    }

    padding-bottom: 6px;
  }

  &.practice.fully-completed-event {
    background: $cal-practice-green;
  }

  &.practice.free-slot {
    background: $cal-practice-free-slot-color1
      repeating-linear-gradient(
        45deg,
        $white 5px,
        $white 5px,
        $cal-practice-free-slot-color1 7px,
        $cal-practice-free-slot-color1 8px
      );
  }

  &.practice.waiting-for-instructor-confirmation-slot {
    background: repeating-linear-gradient(
      45deg,
      $white 5px,
      $white 5px,
      $cal-legends-requests-color1 7px,
      $cal-legends-requests-color2 8px
    );

    * {
      color: $grey-medium;
    }

    .cal-event {
      width: 100%;
      margin: 0;
      border-radius: 0;

      .event-block {
        height: 100%;
        width: 100%;
        min-width: calc(100% - 5px);
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
      }

      .filler {
        flex-grow: 1;
      }

      .calendar-event-title {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: $grey-medium;
        line-height: 13px;
      }
    }
  }

  .status-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .status-icon {
      display: inline-block;
      width: 12px;
      height: 12px;
      vertical-align: sub;
      border-radius: 14px;
      background-color: $grey-soft;

      &.confirmed-completed {
        background-color: $light-green;
      }

      &.waiting-for-learner-completion {
        background-color: $cal-waiting-for-learner-bg;
      }

      &.cancelled {
        background-color: $danger;
      }

      &.not-occurred {
        background-color: $cal-not-occurred-bg;
      }
    }
  }

  .payment-status {
    width: fit-content;
    border-radius: 100px;
    background-color: $cal-payment-status;
    text-transform: uppercase;
    color: $white;
    opacity: 0.92;
    padding-left: 6px;
    padding-right: 8px;
    font-size: 9px;
    font-weight: bold;
    line-height: 20px;
  }

  .practice-lesson-status {
    width: fit-content;
    border-radius: 100px;
    background-color: $white;
    color: $primary-dark;
    padding-left: 6px;
    padding-right: 8px;
    font-size: 9px;
    font-weight: 500;
    opacity: 0.99;
    line-height: 20px;
  }

  &.practice.waiting-for-learner-confirmation-slot {
    background: $cal-waiting-for-learner-bg !important;
    width: 100%;
    margin: 0;
    border-radius: 0;

    .cal-event {
      width: 100%;
      padding: 0;
    }

    .event-block {
      height: 100%;
      width: 100%;
      min-width: calc(100% - 5px);
      padding-left: 3px;
      display: flex;
      flex-direction: column;

      .filler {
        flex-grow: 1;
      }

      .topic-container {
        color: $grey-medium;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
      }

      .learner-name {
        color: $grey-medium;
        font-size: 9px;
        line-height: 6px;
        margin-bottom: 9px;
      }

      .calendar-event-title {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: $grey-medium;
        line-height: 13px;
        position: relative;
        top: 30px;
      }
    }
  }

  &.practice.cancelled-slot {
    background: repeating-linear-gradient(
      45deg,
      $white 5px,
      $cal-practice-exams-bg 5px,
      lighten($cal-practice-exams-bg, 18%) 7px,
      $cal-practice-exams-bg 8px
    ) !important;
    width: 100%;
    margin: 0;
    border-radius: 0;

    .cal-event {
      width: 100%;
      padding: 0;
    }

    .event-block {
      height: 100%;
      width: 100%;
      min-width: calc(100% - 5px);
      padding-left: 3px;
      display: flex;
      flex-direction: column;

      .filler {
        flex-grow: 1;
      }

      .topic-container {
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
      }

      .learner-name {
        color: $white;
        font-size: 9px;
        line-height: 6px;
        margin-bottom: 9px;
      }

      .calendar-event-title {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: $white;
        line-height: 13px;
        position: relative;
        top: 30px;
      }
    }
  }

  &.practice.not-occurred-slot {
    background: repeating-linear-gradient(
      45deg,
      $white 5px,
      $cal-not-occurred-bg 5px,
      lighten($cal-not-occurred-bg, 18%) 7px,
      $cal-not-occurred-bg 8px
    ) !important;
    width: 100%;
    margin: 0;
    border-radius: 0;

    .cal-event {
      width: 100%;
      padding: 0;
    }

    .event-block {
      height: 100%;
      width: 100%;
      min-width: calc(100% - 5px);
      padding-left: 3px;
      display: flex;
      flex-direction: column;

      .filler {
        flex-grow: 1;
      }

      .topic-container {
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
      }

      .learner-name {
        color: $white;
        font-size: 9px;
        line-height: 6px;
        margin-bottom: 9px;
      }

      .calendar-event-title {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: $white;
        line-height: 13px;
        position: relative;
        top: 30px;
      }
    }
  }

  &.practice.practice-exam-slot {
    background: $cal-exam-bg !important;
    color: $white !important;

    &.waiting-for-learner-confirmation-slot {
      background: repeating-linear-gradient(
        45deg,
        $white 5px,
        $cal-exam-bg 5px,
        lighten($cal-exam-bg, 18%) 7px,
        $cal-exam-bg 8px
      ) !important;
      color: $white !important;
    }

    .topic-container {
      color: $white !important;
    }

    .learner-name {
      color: $white !important;
      font-size: 9px;
      line-height: 6px;
      margin-bottom: 9px;
    }
  }

  &.practice.practice-exam-yes {
    //background: #e88d6a !important;
    //color: $white;
    //
    //.topic-container {
    //  color: $white !important;
    //}
  }

  &.practice.practice-exam-no {
    //background: #9c545d !important;
    //color: $white;
    //
    //.topic-container {
    //  color: $white !important;
    //}
  }

  &.block_time {
    .cal-event {
      border-radius: 0;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0;
      border: none;
      background: repeating-linear-gradient(
        45deg,
        $cal-block-time-color1 5px,
        $cal-block-time-color2 5px,
        $cal-block-time-color3 7px,
        $cal-block-time-color4 8px
      );

      .event-block {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        min-width: calc(100% - 5px);
      }

      .filler {
        flex-grow: 1;
      }

      .topic-container {
        font-weight: bold;
        color: $grey-medium;
        text-transform: uppercase;
      }

      .learner-name {
        color: $grey-medium;
        font-size: 9px;
        line-height: 6px;
        margin-bottom: 9px;
      }
    }
  }

  .free-time-block-text-container {
    display: flex;
    font-size: 10px;
    color: $cal-free-block-color1;
    flex-basis: 100%;
    justify-content: center;
  }

  .free-time-block-text-container {
    flex: 100%;
    display: flex;
  }

  .free-time-block-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    min-width: 100%;
    height: 50px;
    z-index: 1;

    i {
      font-size: 12px;
      color: $cal-free-block-color1;
      margin: 0;
      flex: 1;
    }

    &.past {
      cursor: default;
      opacity: 0.4;
    }
  }

  .block-time-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    height: 50px;
    background-color: black-opacity(0.2);
    z-index: 1;

    > i {
      font-size: 12px;
      color: $white;
      margin: 0;
    }
  }
}

.overflow-cont {
  max-width: 100%;
  overflow-x: clip;
  text-overflow: ellipsis;
}

.cal-today {
  background-color: brand-primary-opacity(0.1) !important;
}

.calendar-legends {
  margin-top: 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  color: $grey-medium;

  span[class^='legend-'] {
    margin-right: 20px;
  }

  .legend-color {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 4px;
  }

  .legend-theory {
    .legend-color {
      background: $cal-theory-event;
    }
  }

  .legend-practice {
    .legend-color {
      background: $cal-practice-green;
    }
  }

  .legend-enquiries {
    .legend-color {
      background: $cal-waiting-for-learner-bg;
    }
  }

  .legend-requests {
    .legend-color {
      background: repeating-linear-gradient(
        45deg,
        $white 5px,
        $white 5px,
        $cal-legends-requests-color1 7px,
        $cal-legends-requests-color2 8px
      );
    }
  }

  .legend-practice-exams {
    .legend-color {
      background: $cal-exam-bg;
    }
  }

  .legend-cancellations {
    .legend-color {
      background: repeating-linear-gradient(
        45deg,
        $white 5px,
        $cal-practice-exams-bg 5px,
        lighten($cal-practice-exams-bg, 18%) 7px,
        $cal-practice-exams-bg 8px
      );
    }
  }

  .legend-not-occurred {
    .legend-color {
      background: repeating-linear-gradient(
        45deg,
        $white 5px,
        $cal-not-occurred-bg 5px,
        lighten($cal-not-occurred-bg, 18%) 7px,
        $cal-not-occurred-bg 8px
      );
    }
  }
}

.cal-week-view .cal-day-headers .cal-header {
  outline: none !important;
}

@media only screen and (max-width: 768px) {
  .school-internal-page {
    margin-left: 0;
    margin-right: 0;
  }

  .internal-page-title {
    margin-left: 15px;
    margin-right: 15px;
  }

  .calendar-legends {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media only screen and (min-height: 900px) {
  .cal-week-view {
    .cal-time-events {
      height: 60vh;
    }
  }

  .day-desktop-container {
    .cal-day-view {
      .cal-hour-rows {
        height: 60vh;
      }
    }
  }
}
