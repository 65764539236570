@import 'colors';
@import 'variables';

.payment-status {
  max-width: max-content;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
  font-size: 15px;

  &.payment-pending {
    background-color: rgba($cal-waiting-for-learner-bg, 0.35);
    color: $bright-orange;
  }

  &.payment-green {
    background-color: rgba($brand-secondary, 0.35);
    color: $brand-secondary;
  }

  &.payment-not-paid {
    background-color: rgba($danger, 0.35);
    color: $danger;
  }

  &.payment-refund {
    background-color: rgba($grey-838ca0, 0.35);
    color: $grey-838ca0;
  }

  &.payment-partial {
    background-color: $white;
    color: #f2a13d;
    border: solid 1px #f2a13d;
  }

  &.payment-archived {
    background-color: $white;
    color: $grey-838ca0;
    border: solid 1px $grey-838ca0;
  }

  &.status-confirmed {
    background-color: $white;
    color: $brand-secondary;
    border: solid 1px $brand-secondary;
  }
}
