@import '~sass/colors';

.dashboard-card {
  width: 100%;
  border-radius: 2px 2px 6px 6px;
  box-shadow: 0 1px 7px 0 black-opacity(.25);
  background-color: $white;
  border-top: 5px solid $brand-primary;
}

.dashboard-card-header,
.dashboard-card-content,
.dashboard-card-footer {
  padding-left: 25px;
  padding-right: 25px;
}

.dashboard-card-header {
  margin-top: 12px;
  margin-bottom: 13px;
}

.dashboard-card-header h3 {
  font-size: 18px;
  font-weight: 500;
  color: $primary-dark;
  margin: 0;
}

.dashboard-card hr {
  margin: 0;
}

.dashboard-card-content {
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: $primary-dark;
}

div.left-dashboard-card {
  padding-left: 0;
  padding-right: 0;
}

div.right-dashboard-card {
  padding-left: 0;
  padding-right: 0;
}

.dashboard-item + .dashboard-item,
div.left-dashboard-card + div.right-dashboard-card {
  margin-top: 30px;
}

.dashboard-icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 23px;
  height: 23px;
  min-height: 23px;
  min-width: 23px;
  border-radius: 23px;

  i {
    color: $white;
  }

  &.brand-primary {
    background-color: $brand-primary;
  }

  &.brand-secondary {
    background-color: $brand-secondary;
  }

  &.bright-orange {
    background-color: $bright-orange;
  }

  &.grey-medium {
    background-color: $grey-medium;
  }

  &.body-bg {
    background-color: $body-bg;
  }

  &.small {
    width: 19px;
    height: 19px;
  }
}

.tooltip-container {
  font-size: 18px;
  font-weight: 500;
  color: $grey-very-light;
}

.dashboard-grey-bg {
  background-color: $body-bg;
}

@media only screen and (min-width: 768px) {
  div.left-dashboard-card {
    padding-right: 10px;
  }

  div.right-dashboard-card {
    padding-left: 10px;
  }

  div.left-dashboard-card + div.right-dashboard-card {
    margin-top: 0;
  }
}
