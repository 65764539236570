@import 'colors';

.carousel {
  outline: none;
}

.carousel-fade {
  .carousel-item {
     opacity: 0;
     transition-duration: .6s;
     transition-property: opacity;
   }
}

.carousel-control-prev,
.carousel-control-next {
  cursor: pointer;
}

%arrow {
   height: 26px;
   width: 26px;
   border-radius: 26px;
   opacity: 0.36;
   background-color: $black;
   position: absolute;
   top: 85px;
   cursor: pointer;
 }

.carousel-control-prev-icon {
  @extend %arrow;
  background-image: url('/assets/arrow_left.svg');
}

.carousel-control-next-icon {
  @extend %arrow;
  background-image: url('/assets/arrow_right.svg');
}

@media only screen and (min-width: 768px) {
  %arrow {
    top: 170px;
  }
}
