@import '~@ng-select/ng-select/themes/default.theme.css';

.ng-select-container {
  border-radius: 5px !important;
  border: solid 1px $grey-soft !important;

  input {
    color: transparent;
    text-shadow: 0 0 0 $primary-dark;

    &:focus {
      outline: none;
    }
  }
}

.ng-select.ng-select-single .ng-select-container {
  height: unset;
  padding: 16px 0 12px 16px;
}

.ng-select .ng-select-container .ng-value-container {
  padding-left: 0;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: $primary-dark transparent transparent;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent $primary-dark;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: transparent transparent $primary-dark;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  margin-left: 5px;
  margin-top: 5px;
}

.custom-height-25.ng-select.ng-select-single {
  .ng-select-container {
    padding: 5px;
    padding-bottom: 3px;
    height: 25px;
    min-height: 25px;

    border-radius: 2px !important;
    font-size: 12px;

    .ng-value-container .ng-input > input {
      margin-top: 0;
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items .ng-option {
      font-size: 12px;
    }
  }
}

.custom-height-32.ng-select.ng-select-single {
  .ng-select-container {
    padding: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 32px;
    min-height: 32px;

    font-size: 14px;

    .ng-value-container .ng-input > input {
      margin-top: 0;
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items .ng-option {
      font-size: 14px;
    }
  }
}

.custom-height-37.ng-select.ng-select-single {
  max-width: 200px;
  .ng-select-container {
    padding: 10px;
    height: 37px !important;
    max-width: 200px;
  }

  &.ng-select-163 {
    max-width: 163px;
    .ng-select-container {
      max-width: 163px;
    }
  }
}

.custom-height-40.ng-select.ng-select-single {
  max-width: 200px;
  .ng-select-container {
    padding: 12px;
    height: 40px !important;
    max-width: 200px;
  }

  &.ng-select-183 {
    max-width: 183px;
    .ng-select-container {
      max-width: 183px;
    }
  }

  &.ng-select-172 {
    max-width: 172px;
    .ng-select-container {
      max-width: 172px;
    }
  }

  &.ng-select-153 {
    max-width: 153px;
    .ng-select-container {
      max-width: 153px;
    }
  }

  &.ng-select-140 {
    max-width: 140px;
    .ng-select-container {
      max-width: 140px;
    }
  }
}

.custom-height-45.ng-select.ng-select-single {
  .ng-select-container {
    height: 45px;
  }
}

.custom-height-50.ng-select.ng-select-single {
  .ng-select-container {
    height: 50px;
  }
}

.rounded-corners.ng-select.ng-select-single {
  .ng-select-container {
    border-radius: 20px !important;
  }
}

.transparent-select {
  .ng-select-container {
    display: flex;
    align-items: flex-start;

    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  .ng-dropdown-panel,
  .ng-dropdown-panel-items,
  .ng-dropdown-panel-items > div {
    background: transparent !important;
  }

  .ng-option-disabled {
    background: transparent !important;
    border: none;
    box-shadow: none;
  }

  .ng-value {
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    color: $grey-dark;
    width: 100%;
    margin-right: 12px;
  }

  .ng-arrow-wrapper {
    font-size: 14px;
    text-align: center;
    color: $grey-dark;
    margin-top: 4px;
  }

  .select-subtitle {
    font-size: 14px;
    line-height: 1.86;
    text-align: right;
    color: $primary-dark;
    font-weight: normal;
  }
}

.no-top-padding {
  .ng-select-container {
    padding-top: 0 !important;
  }
}

.ng-option-disabled {
  cursor: default !important;
}
