@import 'colors';

.event-dialog {
  .mat-dialog-content {
    max-height: unset !important;
  }

  .dialog-container {
    padding: 40px;
    position: relative;
  }

  .dialog-main-title {
    font-size: 34px;
    font-weight: bold;
    line-height: 1.29;
    color: $primary-dark;
  }

  .dialog-close-button {
    position: absolute;
    right: 22px;
    top: 20px;
    color: $grey-very-light;
    cursor: pointer;
  }

  .main-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 66px;
    height: 66px;
    border-radius: 66px;
    background-color: $grey-soft;
    margin-bottom: 24px;

    &.clickable {
      cursor: pointer;
    }

    &.transparent {
      background-color: transparent;
    }

    > img {
      width: calc(100% - 5px);
    }
  }

  .dialog-back-btn {
    font-size: 32px;
    text-align: center;
    color: $primary-dark;
  }

  .dialog-main-description {
    font-size: 20px;
    line-height: 1.6;
    color: $primary-dark;
    margin-top: 32px;
    margin-bottom: 40px;

    > span {
      font-weight: bold;
    }
  }

  .dialog-section-title {
    font-size: 20px;
    font-weight: bold;
    color: $primary-dark;
    margin-top: 32px;
  }

  .dialog-section-subtitle {
    font-size: 16px;
    color: $primary-dark;
    margin-top: 16px;
  }

  .dialog-section-explanation {
    opacity: 0.7;
    font-size: 16px;
    margin-top: 16px;
  }

  .left-spacing {
    margin-left: 40px;
  }

  div {
    font-size: 18px;
    color: $primary-dark;
  }

  .dialog-main-content-title {
    font-size: 20px;
    color: $primary-dark;
    margin-top: 20px;
    margin-bottom: 32px;
  }

  .metadata {
    margin-top: 16px;

    > i {
      font-size: 18px;
      font-weight: 300;
      color: $primary-dark;
      margin-right: 8px;
    }
  }

  .primary-link {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }

  .times-column {
    width: 140px;
  }

  .time-picker-row {
    display: flex;
  }

  .time-picker-row + .time-picker-row {
    margin-top: 16px;
  }

  .datepicker-wrapper {
    width: 220px;
  }

  .add-time-range-container {
    margin-top: 16px;
  }

  a.link {
    font-size: 16px;
    color: $brand-primary;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .event-options-buttons-container {
    margin-top: 48px;

    .primary-btn + .primary-btn {
      margin-top: 24px;
    }

    .primary-link {
      display: block;
      margin-top: 24px;
    }
  }

  .days-container {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    > .day {
      margin-top: 16px;
    }
  }

  .public-holidays-disclaimer {
    border-radius: 4px;
    background-color: $body-bg;

    font-size: 16px;
    line-height: 1.5;
    color: $primary-dark;
    padding: 12px;

    margin-top: 32px;
    margin-bottom: 40px;
  }

  .event-duration-wrapper {
    position: relative;
    display: inline-block;
    margin-top: 16px;
  }

  .event-minutes-label {
    position: absolute;
    top: 13px;
    right: 35px;
    color: $primary-dark;
  }

  .input {
    border-radius: 5px;
    border: solid 1px $grey-soft;
    padding: 12px 16px;
  }

  .input-150 {
    width: 150px;
  }

  .duration-values-wrapper {
    margin-bottom: 56px;
  }

  .pick-end-date-container {
    margin-bottom: 40px;
  }

  &.cdk-overlay-pane {
    display: inline-block;
    position: relative;
    text-align: left;
    white-space: normal;
  }

  .success-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 90px;
    height: 90px;
    border-radius: 90px;
    background-color: $light-green;
    margin-top: 60px;
    margin-bottom: 24px;

    > i {
      font-size: 60px;
      font-weight: 300;
      color: $white;
    }
  }

  .success-icon-container + .dialog-section-title {
    margin-top: 24px;
  }

  .success-message {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 40px;
  }

  .buttons-row {
    margin-top: 40px;

    .primary-btn + .primary-btn {
      margin-left: 16px;
    }
  }

  .timeslots-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: -16px;
    margin-left: -16px;
  }

  .timeslot {
    border-radius: 4px;
    background-color: $body-bg;
    cursor: pointer;

    padding: 8px 16px;
    margin-left: 16px;
    margin-top: 16px;

    &.selected {
      background-color: $brand-primary;
      color: $white;
    }
  }

  .school-address-info {
    font-size: 16px;
    line-height: 1.5;
    color: $primary-dark;
    padding: 16px;
    border-radius: 4px;
    background-color: $body-bg;
    max-width: 300px;

    > div {
      font-weight: bold;
      margin-bottom: 8px;
    }
  }

  .lesson-title {
    margin-top: 32px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: $primary-dark;
  }

  .lesson-message {
    border-radius: 5px;
    border: solid 1px $grey-soft;
    background-color: $white;
    width: 100%;
    resize: none;
    padding: 12px 16px;
  }

  .learner-name {
    display: flex;
    align-items: center;

    font-size: 24px;
    font-weight: bold;
    line-height: 1.42;
    color: $primary-dark;
  }

  .learner-info-card {
    padding: 24px 24px 32px 24px;
    background-color: $body-bg;

    margin-top: 40px;
    margin-bottom: 28px;

    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      background-color: $grey-soft;

      margin-right: 16px;
    }

    .row1 {
      display: flex;
    }
  }

  .learner-message-title {
    font-size: 18px;
    font-weight: bold;
    color: $primary-dark;
  }

  .learner-message-card {
    border-radius: 8px;
    background-color: $body-bg;
    padding: 16px;
    margin-top: 16px;

    font-size: 16px;
    line-height: 1.31;
    color: $grey-medium;
  }

  .write-message-header {
    font-size: 20px;
    font-weight: bold;
    color: $primary-dark;
    margin-top: 40px;
  }

  .warning-notification {
    display: flex;

    background-color: color-with-opacity($cal-practice, 0.1);
    padding: 16px;
    margin-bottom: 32px;
  }

  .exclamation-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 0 0 32px;
    height: 32px;
    border-radius: 32px;
    background-color: $cal-practice;

    margin-right: 12px;

    > i {
      font-size: 20px;
      color: $white;
    }
  }

  .error-notification {
    display: flex;

    background-color: color-with-opacity($progress-bar-4, 0.1);
    padding: 16px;
    margin-bottom: 32px;

    > .exclamation-icon-container {
      background-color: $progress-bar-4;
    }
  }

  .cancellation-notification {
    display: flex;

    background-color: color-with-opacity($cal-practice, 0.1);
    padding: 16px;
    margin-bottom: 32px;
  }

  .cancellation-confirmation {
    margin-top: 32px;
    font-weight: bold;
    font-size: 20px;
  }

  .event-address {
    margin-top: 8px;
    margin-left: 26px;
    line-height: 1.56;
  }
}

.availability-dialog {
  .mat-dialog-container {
    width: 520px;
  }
}

.custom-dialog-container.event-dialog {
  > .mat-dialog-container {
    overflow: visible;
  }
}

.custom-dialog-container.event-dialog {
  &.edit-theory-lessons-dialog {
    min-width: 636px !important;
  }

  &.mark-lesson-paid-in-cash-dialog {
    min-width: 520px !important;
    max-width: 520px !important;
  }
}

.custom-dialog-container.event-dialog {
  &.add-practice-lesson-dialog,
  &.practice-lesson-rating-dialog,
  &.pending-event-dialog {
    max-width: 620px !important;
  }
}

.custom-dialog-container.event-dialog {
  &.add-theory-lessons-dialog,
  &.add-practice-lesson-dialog,
  &.pending-event-dialog {
    min-width: 620px !important;
  }
}

.custom-dialog-container.event-dialog {
  &.add-theory-lessons-dialog,
  &.add-block-time-dialog,
  &.confirmed-event-dialog {
    min-width: 520px !important;
    max-width: 520px !important;
  }
}

.cdk-global-overlay-wrapper {
  pointer-events: auto;
  overflow: auto;
  text-align: left;
}

.cdk-global-overlay-wrapper::before {
  content: '';
  display: inline-block;
  height: 100%;
  white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  .event-dialog.confirmed-event-dialog {
    min-width: 750px;
  }
}

@media only screen and (max-width: 768px) {
  .availability-dialog {
    .mat-dialog-container {
      min-width: unset !important;
      width: 100%;
      max-width: 100% !important;
    }
  }

  .custom-dialog-container.event-dialog {
    max-width: 100% !important;

    &.edit-theory-lessons-dialog {
      min-width: unset !important;
      max-width: 100% !important;
    }

    &.mark-lesson-paid-in-cash-dialog {
      min-width: unset !important;
      width: 90% !important;
      max-width: 520px !important;
    }
  }

  .custom-dialog-container.event-dialog {
    &.add-practice-lesson-dialog,
    &.practice-lesson-rating-dialog,
    &.pending-event-dialog {
      max-width: 100% !important;
    }
  }

  .custom-dialog-container.event-dialog {
    &.add-theory-lessons-dialog,
    &.add-practice-lesson-dialog,
    &.pending-event-dialog {
      min-width: unset !important;
    }
  }

  .custom-dialog-container.event-dialog {
    &.add-theory-lessons-dialog,
    &.add-block-time-dialog,
    &.confirmed-event-dialog {
      min-width: unset !important;
      max-width: 100% !important;
    }
  }

  .width-50 {
    width: 100% !important;
  }

  .buttons-row {
    flex-direction: column;

    .primary-btn + .primary-btn {
      margin-left: 0 !important;
      margin-top: 16px;
      width: 100%;
    }
  }

  .left-spacing {
    margin-left: 0 !important;
  }

  .primary-btn {
    padding-left: 8px !important;
    padding-right: 8px !important;
    width: 100% !important;
  }
}
