/* cards */
$card-padding-head:   0px 24px;
$card-padding-body:   0px 24px;
$card-padding-footer: 0px 24px;

.base-card{
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: $white;
  box-shadow: 0 1px 3px 0 black-opacity(.25);
  .title{
    color: $primary-dark;
    margin: 0;
    padding: 0;
    }
}
.base-card-head   {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  min-height:50px;
  padding: $card-padding-head;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-top: 5px solid $brand-primary;
    .title{
    color: $primary-dark;
    margin: 0;
    padding: 0;
    }
}
.row{
  margin-left: 15px;
  margin-right: 15px;
}
.pd-left-0{
  padding-left: 0;
}
.pd-right-0{
  padding-right: 0;
}
.m-right-0{
  margin-right: 0;
}
.m-left-0{
  margin-left: 0;
}
.base-card-body   { padding: $card-padding-body; }
.base-card-footer { padding: $card-padding-footer; }
.ta-right{
  text-align: right;
}
.pd-bottom14{
  padding-bottom: 14px;
}
.blue-card-number{
  font-weight: bold;
  font-size: 30px;
  color: $white;
}
.blue-card{
  background: $brand-primary;
  height: 130px;
  border-radius: 2px;
}
.blue-card-label{
  color: $white;
  opacity: 0.8;
}
.display-table{
  display: table;
  width: 100%;
}
.display-t-cell{
  display: table-cell;
}
.vert-a-bottom{
  vertical-align: bottom;
}
.watermark-blue-card{
  font-size: 100px;
  opacity: 0.08;
  position: absolute;
  bottom: -10px;
  left: -2px;
  color: $white;

}
.relative{
  position: relative;
}
ul.flags-list{
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: table;
  li.flag-list-item{
    display: table-cell;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    background: $grey-soft;
    text-align: center;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }
  li.flag-spacer{
    width: 10px;
  }
  li.add-new-flag{
    i{
      color: $grey-light;
    }
  }
}
.bd-top{
  border-top: 1px solid $grey-border;

}
.blue-icon{
  color: $brand-primary;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-15{
  margin-bottom: 15px;
}
.m-top-30{
  margin-top: 30px;
}
@media screen and (max-width: 767px){
  .school-img-container{
    img{
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
  }
  .school-name-container{
    text-align: center;
    margin-top: 15px;
  }
  .blue-cards-container{
    padding: 0;
    margin-bottom: 20px;
  }
}
.save-button{
  width: 200px !important;
  float: right;
}
.cancel-button{
  width: 200px !important;
  margin-left: 20px;

}
td.input-price{
  position: relative;
}
.input-edit-icon{
  position: absolute;
  right: 25px;
  top: 21px;
}
