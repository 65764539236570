@import 'colors';
@import 'variables';

.mobile-label {
  display: none;
}

@media only screen and (max-width: $mobile-table-breakpoint-max) {
  .mobile-label {
    width: 130px;
    padding-right: 5px;
    display: inline-block;
    font-weight: bold;
    text-align: left;
  }

  .mat-header-row {
    display: none;
  }

  .mat-row {
    flex-direction: column;
    align-items: stretch;
    padding: 14px 0;
  }

  .mat-table .mat-cell {
    padding: 17px !important;
    justify-content: space-between;
    border-top: 1px solid black-opacity(.12);
  }

  mat-footer-row::after, mat-header-row::after, mat-row::after {
    display: none !important;
    min-height: 0 !important;
  }
}
