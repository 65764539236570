@import '~angular-calendar/scss/angular-calendar';
@import '~bootstrap/scss/bootstrap';
@import 'colors';
@import 'variables';
@import 'flexbox';
@import 'type';
@import 'cards';
@import 'forms';
@import 'buttons';
@import 'tables';
@import 'carousel';
@import 'driveddy-material-theme';
@import 'driveddy-table';
@import 'driveddy-internal-page';
@import 'info-page';
@import 'dashboard';
@import 'material-select';
@import 'toastr';
@import 'ng-select';
@import 'calendar';
@import 'event-dialogs';
@import 'signup';
@import 'activehosted';
@import 'modal';
@import 'payment-statuses';

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  color: $grey-medium;
  background-color: $body-bg;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}

.ng-invalid.ng-touched {
  border-color: $danger;
}

/* Global Styles */

.approved {
  > i,
  > svg {
    color: $success;
  }

  > span {
    color: $grey-medium;
  }
}

.unapproved {
  > i,
  > svg {
    color: $danger;
  }

  > span {
    color: $grey-medium;
  }
}

.img-placeholder {
  background-color: $grey-medium;
}

p {
  padding: 0;
  margin: 0;
}

.bd-left {
  border-left: 1px solid $grey-border;
}

.bd-top {
  border-top: 1px solid $grey-border;
}

.bd-right {
  border-right: 1px solid $grey-border;
}

.bd-bottom {
  border-bottom: 1px solid $grey-border;
}

/* Paddings */
.pd0,
.p0 {
  padding: 0px;
}

.pd-top5 {
  padding-top: 5px;
}

.pd-top8 {
  padding-top: 8px;
}

.pd-top24 {
  padding-top: 24px;
}

.pd-bottom24 {
  padding-bottom: 24px;
}

.pd-right0 {
  padding-right: 0px;
}

.m-top-8 {
  margin-top: 8px;
}

.m-top-16 {
  margin-top: 16px;
}

html,
body {
  height: 100%;
}

.mat-sidenav-content {
  display: flex !important;
  flex-direction: column !important;
}

.mat-sidenav-container {
  height: 100% !important;
}

.internal-mat-sidenav.narrow {
  .mat-drawer-inner-container {
    overflow: visible;
  }
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.table-head {
  border: 0 solid transparent;
}

.table thead th {
  border-bottom: 0 solid transparent;
}

tbody tr:first-child {
  th,
  td {
    border-top: 0 solid transparent;
  }
}

.middle {
  vertical-align: middle;
}

eddy-login.ng-star-inserted {
  display: flex;
  justify-content: center;
  align-items: center;
}

mat-sidenav-content:nth-child(2) {
  flex: 1 0 auto !important;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0 !important;
  border-radius: 8px;
}

.calendar-events-dialog .mat-dialog-container {
  border-radius: 8px;
}

@media only screen and (max-width: 575px) {
  .contact-dialog-container {
    height: 332px !important;
  }

  .contact-dialog-container .mat-dialog-container,
  .contact-dialog-container .mat-dialog-content {
    overflow: hidden !important;
  }
}

.custom-dialog-container {
  max-width: 93vw !important;
  border-radius: 2px;
}

.ot-disclaiemr-dialog {
  max-width: 100vw !important;
}

@media only screen and (max-width: 768px) {
  .cdk-overlay-container:has(.ot-disclaiemr-dialog) {
    .cdk-overlay-backdrop {
      background: $white;
    }
    .mat-dialog-container {
      box-shadow: none;
    }
    .cdk-global-overlay-wrapper {
      align-items: start !important;
    }
  }
}

.save-button {
  height: 46px;
  border-radius: 2px;
  border: solid 1px $brand-primary;
  background-color: $brand-primary;
  color: $white;
  margin-left: 5px;
}

.save-button:focus {
  outline: none;
  text-decoration: none !important;
}

.save-button:hover {
  cursor: pointer;
}

button[disabled='disabled'],
button:disabled {
  background-color: $body-bg;
  color: $grey-soft;
  border: solid 1px $grey-soft;
}

.cancel-button {
  height: 46px;
  border-radius: 2px;
  border: solid 1px $danger;
  background-color: $danger;
  color: $white;
  float: right;
  margin-left: 5px;
}

.cancel-button:focus {
  outline: none;
  text-decoration: none !important;
}

.cancel-button:hover {
  cursor: pointer;
}

.delete-button {
  height: 46px;
  border-radius: 2px;
  border: solid 1px $danger;
  background-color: $danger;
  color: $white;
  float: right;
  margin-left: 5px;
}

.delete-button:focus {
  outline: none;
  text-decoration: none !important;
}

.delete-button:hover {
  cursor: pointer;
}

.yes-button {
  font-size: 14px;
  padding: 4px 24px;
  border-radius: 2px;
  background-color: $grey-soft;
  margin-top: 6px;
  max-height: 24px;
  border: none;
  color: $primary-dark;
}

.yes-button:focus {
  outline: none;
  text-decoration: none !important;
}

.yes-button:hover {
  cursor: pointer;
}

.title {
  font-size: 18px;
  color: $primary-dark;
  padding-bottom: 24px;
  margin-left: 30px;
}

.form-control {
  background-color: $body-bg;
  border: none;
  width: 100%;
}

.form-control:focus {
  box-shadow: none;
}

.form-label {
  font-size: 14px;
  color: $grey-light;
}

//@media only screen and (max-width: 795px) {
//  .mat-dialog-content {
//    height: 100%;
//  }
//}

@media only screen and (max-width: 795px) {
  .dialog-container {
    height: 100%;
    max-height: 100%;
  }
  .mat-dialog-content {
    height: 100% !important;
    max-height: 100% !important;
  }

  .md-dialog-container {
    height: 100% !important;
    position: fixed !important;
    top: 0px !important;
  }
}

.grey-dark {
  color: $primary-dark;
}

.form-control {
  border: 1px solid $grey-soft !important;
}

.btn-accept {
  background-color: $brand-primary;
}

.btn-decline {
  background-color: $danger;
}

.mat-button-wrapper:focus,
.mat-icon-button:focus,
button:focus,
a:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100% !important;
}

button:hover {
  filter: brightness(90%);
}

input.ng-touched.ng-invalid {
  border: 1px solid $danger !important;
}

form.ng-invalid {
  border: none;
}

.no-overflow {
  overflow: hidden;
}

iframe#launcher {
  display: none;
}

/* extra large container */
@media only screen and (min-width: 1400px) {
  .xl-container {
    max-width: 1320px;
  }
}

@media only screen and (min-width: 1600px) {
  .xl-container {
    max-width: 1520px;
  }
}

@media only screen and (min-width: 1800px) {
  .xl-container {
    max-width: 1720px;
  }
}

.custom-dialog-container .mat-dialog-actions {
  margin: 0;
  padding: 0;
  flex: 0 0 50px;
}

.event-dialog .mat-dialog-actions {
  justify-content: flex-end;
}

.custom-dialog-container .mat-dialog-content {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.download-training-doc-dialog .mat-dialog-container {
  padding: 34px 30px 64px 32px;
  border-radius: 12px;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 20%);
}

.download-training-doc-dialog.download-training-doc-update-dialog
  .mat-dialog-container {
  padding: 34px 30px 52px 32px;
}

.download-manual-transmission-proof-dialog .mat-dialog-container {
  padding: 34px 30px 30px 32px;
  border-radius: 12px;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 20%);
}

.sign-up-dialog .mat-dialog-container {
  max-width: 420px !important;
  border-radius: 8px;
}

.welcome-dialog .mat-dialog-container {
  max-width: 795px !important;
  border-radius: 8px;
  padding: 0;

  .mat-dialog-content {
    max-height: unset !important;
  }
}

.ot-deactivated-info-dialog .mat-dialog-container {
  .dialog-container {
    position: relative;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 24px;
    color: $grey-very-light;
    cursor: pointer;
  }
}

.dvfff-news-dialog .mat-dialog-container {
  max-width: 900px !important;
  border-radius: 8px;
  padding: 0;
  position: relative;

  .mat-dialog-content {
    max-height: unset !important;
    padding-bottom: 10px;
  }
}

.account-exists-dialog .mat-dialog-container {
  padding: 33px 46px 41px;
  border-radius: 12px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
}

.add-school-learner-dialog .mat-dialog-container {
  border-radius: 8px !important;

  // @media only screen and (max-width: 500px) {
  //   min-height: 90vh;
  //   height: 90vh;
  // }
}

.success-with-actions-dialog .mat-dialog-container {
  padding: 0 32px 52px 36px !important;
  border-radius: 8px;

  .close-button {
    font-size: 36px;
    font-weight: 300;
    color: $grey-soft;
  }

  .modal-title {
    display: none;
  }
  .modal-header {
    justify-content: flex-end;
    margin-right: -10px;
    padding: 0;
    border: none;
  }
  .modal-body {
    margin-bottom: 44px;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    color: $primary-dark;
  }
  .mat-dialog-actions {
    min-height: unset;
  }
  .modal-footer {
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding: 0;
    border: none;

    .btn {
      padding: 8px 31px;
      border-radius: 4px;
      background-color: $brand-primary;
      text-transform: none;

      &.btn-yes {
        margin-left: 0;
        margin-right: 30px;
      }

      &.btn-no {
        border: none;
        color: $white;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    min-height: 90vh;
    height: 90vh;
    padding-bottom: 27px !important;

    .modal-body {
      margin-top: 54px;
      font-size: 16px;
      line-height: normal;
    }

    .modal-footer {
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .btn {
        width: 100%;

        &.btn-yes {
          margin-right: 0 !important;
        }

        &.btn-no {
          margin-right: 0 !important;
          margin-bottom: 18px;
        }
      }
    }
  }
}

.success-dialog .mat-dialog-container {
  padding: 0 29px 34px 29px !important;
  border-radius: 8px;

  .mat-dialog-content {
    padding: 0 21px;
  }

  .close-button {
    font-size: 36px;
    font-weight: 300;
    color: $grey-soft;
  }

  .modal-title {
    display: none;
  }

  .content-title {
    display: block;
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  .modal-header {
    justify-content: flex-end;
    margin-right: -10px;
    padding: 0;
    border: none;
  }
  .modal-body {
    margin-bottom: 59px;
    padding: 0;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }
  .mat-dialog-actions {
    min-height: unset;
  }
  .modal-footer {
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding: 0;
    border: none;

    .btn {
      padding: 8px 31px;
      border-radius: 4px;
      background-color: $brand-primary;
      text-transform: none;

      &.btn-yes {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    min-height: 90vh;
    height: 90vh;
    padding-bottom: 27px !important;

    .modal-body {
      margin-top: 54px;
      font-size: 16px;
      line-height: normal;
    }

    .modal-footer {
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .btn {
        width: 100%;

        &.btn-yes {
          margin-right: 0 !important;
        }

        &.btn-no {
          margin-right: 0 !important;
          margin-bottom: 18px;
        }
      }
    }
  }
}

.price-list-success-dialog .mat-dialog-container {
  padding: 40px 46px 34px 46px !important;

  .modal-header {
    display: none;
  }

  .mat-dialog-content {
    padding: 0;
  }

  .content-title {
    margin-bottom: 11px;
    text-align: left;
    font-size: 18px;
  }

  .modal-body {
    margin-bottom: 16px;
    text-align: left;
  }

  .modal-footer .btn.btn-yes {
    border-radius: 5.1px !important;
    border: solid 1.3px $brand-primary !important;
    background-color: transparent !important;
    color: $brand-primary !important;
  }
}

.offer-succes-dialog .mat-dialog-container {
  padding: 36px 37px 49px 37px !important;
  border-radius: 24px;
  color: $primary-dark;

  .dialog-container {
    position: relative;
  }

  .modal-header {
    position: absolute;
    right: -10px;
    top: -40px;
  }

  .mat-dialog-content {
    padding: 0;
  }

  .check {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .content-title {
    margin-bottom: 13px;
    font-size: 40px;
    line-height: 48px;
  }

  .modal-body {
    margin-bottom: 0;
    font-size: 24px;
    line-height: 32px;
  }

  .mat-dialog-actions {
    display: none;
  }
}

.ot-deactivated-dialog .mat-dialog-container {
  padding: 36px 37px 49px 37px !important;
  border-radius: 8px;
  color: $primary-dark;

  .dialog-container {
    position: relative;
  }

  .modal-header {
    position: absolute;
    right: -10px;
    top: -40px;
  }

  .mat-dialog-content {
    padding: 0;
  }

  .check {
    margin-top: 0;
    margin-bottom: 18px;
  }

  .content-title {
    margin-bottom: 30px;
    font-size: 30px;
    line-height: normal;
  }

  .modal-body {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.37;
  }

  .mat-dialog-actions {
    display: none;
  }
}

.confirm-yes-dialog .mat-dialog-container {
  padding: 10px 29px 34px 29px !important;
  border-radius: 8px;

  .mat-dialog-content {
    padding: 0 21px;
  }

  .close-button {
    font-size: 36px;
    font-weight: 300;
    color: $grey-soft;
  }

  .modal-title {
    display: block;
  }

  .content-title {
    display: block;
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
  }
  .modal-header {
    justify-content: space-between;
    align-items: baseline;
    margin-right: -10px;
    padding: 0;
    padding-bottom: 15px;
    border: none;
  }
  .modal-body {
    margin-bottom: 26px;
    padding: 0;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
  }
  .mat-dialog-actions {
    min-height: unset;
  }
  .modal-footer {
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding: 0;
    border: none;

    .btn {
      padding: 8px 31px;
      border-radius: 4px;
      background-color: $brand-primary;
      text-transform: none;

      &.btn-yes {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    padding-bottom: 27px !important;

    .modal-body {
      font-size: 16px;
      line-height: normal;
    }

    .modal-footer {
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .btn {
        width: 100%;

        &.btn-yes {
          margin-right: 0 !important;
        }

        &.btn-no {
          margin-right: 0 !important;
          margin-bottom: 18px;
        }
      }
    }
  }
}

.ok-dialog-rounded .mat-dialog-container {
  padding: 24px 30px 35px 30px;
  border-radius: 12px;

  .mat-dialog-title {
    background-color: initial !important;
    justify-content: left !important;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: $primary-dark;
  }

  #main-content {
    margin: 0px;
    font-size: 18px;
    line-height: 1.56;
    color: $primary-dark;
  }

  .mat-dialog-actions {
    padding-right: 0px !important;

    #ok-button {
      width: 137px;
      height: 45px;
      margin: 24px 0 0 64px;
      padding: 11px 28px 13px;
      border-radius: 5px;
      background-color: $brand-primary;
      border: none;

      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }
  }
}

.ok-dialog-rounded-auto-btn .mat-dialog-container {
  .mat-dialog-actions {
    #ok-button {
      flex: 0 0 auto;
      width: auto;
    }
  }
}

.theory-question-ok-dialog .mat-dialog-container {
  padding: 70px 105px 82px 105px;
  border-radius: 12px;

  .dialog-container {
    overflow: visible;
    min-height: 429px;
  }

  .mat-dialog-title {
    flex: 0 0 auto !important;
    background-color: initial !important;
    margin-bottom: 33px !important;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.91;
    letter-spacing: normal;
    color: $grey-dark;

    .close-button {
      right: -85px;
      top: -250px;
    }
  }

  #main-content {
    margin: 0px;
    font-size: 18px;
    line-height: 1.33;
    color: $grey-dark;
    text-align: center;
  }

  .mat-dialog-actions {
    flex: 0 0 auto;
    margin-top: 35px;
    margin-bottom: 0 !important;
    padding: 0px !important;
    justify-content: center !important;

    #ok-button {
      flex: 0 0 auto;
      width: auto;
      height: 47px;
      padding: 12px 28px 13px;
      border-radius: 25px;
      background-color: $brand-secondary;
      border: none;

      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }
  }
}

.online-theory-activation-pending .mat-dialog-container {
  #main-content {
    margin: 0px;
    font-size: 14px;
    line-height: 1.56;
    color: $primary-dark;
  }

  .mat-dialog-actions {
    padding-right: 0px !important;

    #ok-button {
      width: 218px;
      height: 45px;
      margin: 24px 0 0 64px;
      padding: 11px 62px 13px;
      border-radius: 5px;
      background-color: $brand-primary;
      border: none;

      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }
  }
}

.ok-dialog-center .mat-dialog-container {
  padding: 40px 44px 39px 44px;
  border-radius: 12px;

  .dialog-container {
    overflow: initial;
  }

  .close-button {
    right: -27px;
    top: -27px;
  }

  .mat-dialog-title {
    justify-content: center !important;
  }

  .mat-dialog-content {
    padding: 0 26px;
    margin-bottom: 23px;
    text-align: center;
  }

  .mat-dialog-actions {
    justify-content: center !important;
    margin-bottom: 0 !important;
    padding-right: 0px !important;

    #ok-button {
      flex: 0 0 100%;
      margin: 0;
      max-width: 214px;
      width: auto;
      height: 45px;
      padding: 11px 28px 13px;
      background-color: $brand-primary;
    }
  }
}

.offer-dialog {
  @media screen and (max-width: 768px) {
    max-width: 90vw !important;
  }

  .mat-dialog-container {
    padding: 44px 79px 100px 82px;
    border-radius: 12px;

    @media screen and (max-width: 768px) {
      padding: 41px 40px 90px 48px !important;
    }
  }
}

.yes-no-dialog .mat-dialog-container {
  border-radius: 8px;

  @media only screen and (max-width: 500px) {
    padding-bottom: 27px !important;

    .modal-title {
      font-size: 1.4rem;
    }

    .modal-footer {
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .btn {
        width: 100%;

        &.btn-yes {
          margin-right: 0 !important;
          margin-left: 0;
        }

        &.btn-no {
          margin-right: 0 !important;
          margin-bottom: 18px;
        }
      }
    }
  }
}

.custom-dialog-container.event-dialog .mat-dialog-content {
  overflow: visible;
  -webkit-overflow-scrolling: touch;
}

.choose-license-dialog {
  mat-dialog-container {
    border-radius: 8px;

    .mat-dialog-content {
      height: 100%;
    }
  }
}

.video-player-dialog {
  mat-dialog-container {
    padding: 0;
    overflow: hidden;
    border-radius: 8px;
  }
}

.mat-dialog-container {
  overflow: visible;
}

.h-10px {
  height: 10px;
}

.h-30px {
  height: 30px;
}

.w-50px {
  width: 50px;
  display: inline-block;
}

.no-left-space {
  margin-left: 0;
  padding-left: 0;
}

.no-right-space {
  margin-right: 0;
  padding-right: 0;
}

.no-side-space {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-side-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.h-75px {
  height: 75px;
}

.h-44px {
  height: 44px;
}

.inner-container {
  padding-top: 48px;
}

.headline {
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primary-dark;
}

.subHeadline {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primary-dark;
}

.shadow-card {
  background-color: $white;
  box-shadow: 0 0 7px 0 black-opacity(0.25);
  border-radius: 6px;
  padding: 20px 15px 0;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}

.d-flex-justify-center {
  display: flex;
  justify-content: center;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.position-relative {
  position: relative;
}

.d-flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.d-flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}

.driveddy-mat-checkbox {
  .mat-checkbox-inner-container {
    width: 18px;
    height: 18px;
  }

  .mat-checkbox-frame {
    border-radius: 2px;
    border: solid 1px $grey-very-light;
  }
}

.driveddy-mat-radio-button {
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    width: 20px;
    height: 20px;
  }

  .mat-radio-outer-circle {
    border: solid 1px $grey-very-light;
  }
}

.medium-rectangle-img {
  width: 300px;
  height: 250px;
  object-fit: cover;
}

.leaderboard-desktop-img {
  width: 728px;
  height: 90px;
  object-fit: cover;
}

.leaderboard-mobile-img {
  width: 300px;
  height: 50px;
  object-fit: cover;
}

/* image drop */

.no-logo-view > .ngx-file-drop__drop-zone {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
}

.no-logo-view > .ngx-file-drop__drop-zone > div.ngx-file-drop__content {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 black-opacity(0.24);
  background-color: $white;
  position: relative;
}

.image-logo-view > .ngx-file-drop__drop-zone {
  width: 100% !important;
  height: 100% !important;

  border-radius: 5px !important;
  border: 1px dashed $grey-soft !important;
}

.has-image .image-logo-view .ngx-file-drop__drop-zone {
  border: none !important;
}

.cover-image-view > .ngx-file-drop__drop-zone {
  border: none !important;
}

.image-logo-view > .ngx-file-drop__drop-zone > div.ngx-file-drop__content {
  flex-direction: column;
  position: relative;

  width: 100%;
  height: 100%;
}

.border-radius-5 .ngx-file-drop__drop-zone img {
  border-radius: 5px;
}

.border-radius-15 .ngx-file-drop__drop-zone img {
  border-radius: 15px;
}

.has-image .image-logo-view div.ngx-file-drop__drop-zone:hover,
.has-image .no-logo-view div.ngx-file-drop__drop-zone:hover {
  filter: brightness(90%);
}

.no-top-padding {
  padding-top: 0 !important;
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.driveddy-form .untouched.ng-invalid {
  border: 1px solid $danger;
}

.back-btn {
  display: flex;
  align-items: center;

  font-size: 18px;
  color: $primary-dark;
  margin-top: 40px;

  > i {
    font-size: 18px;
    font-weight: 300;
    color: $primary-dark;
    margin-right: 10px;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  > span {
    font-size: 18px;
    color: $primary-dark;

    margin-left: 10px;
  }
}

.font-bold {
  font-weight: bold;
}

.ghost-add-btn {
  padding: 5px 12px;
  border-radius: 2px;

  font-size: 14px;
  color: $brand-primary;
  border: 1px solid $brand-primary;
  background-color: transparent;
}

.no-top-space {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

#request-information-form-container {
  form {
    margin-top: 0 !important;
    padding-top: 0 !important;
    width: 100%;
  }
}

.ng-select-small {
  .ng-select.ng-select-single .ng-select-container {
    height: unset;
    padding: 10px 0 12px 16px;
  }
}

.display-none {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.warning-message-card,
.error-message-card {
  display: flex;

  padding: 16px;
  margin-top: 24px;

  font-size: 16px;
  line-height: 1.44;
  color: $primary-dark;

  border-radius: 4px;

  .exclamation-circle {
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 0 0 32px;
    height: 32px;
    border-radius: 32px;
    margin-right: 12px;
  }

  i {
    font-size: 20px;
    color: $white;
  }
}

.warning-message-card {
  background-color: color-with-opacity($cal-practice, 0.1);

  .exclamation-circle {
    background-color: $cal-practice;
  }
}

.error-message-card {
  background-color: color-with-opacity($danger, 0.1);

  .exclamation-circle {
    background-color: $danger;
  }
}

.text-align-left {
  text-align: left;
}

@media only screen and (max-width: 320px) {
  .driveddy-mat-checkbox {
    font-size: 14px;

    .mat-checkbox-inner-container {
      width: 14px;
      height: 14px;
    }
  }

  .driveddy-mat-radio-button {
    font-size: 14px;

    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      width: 14px;
      height: 14px;
    }
  }
}

#chat-widget-container {
  z-index: 999 !important;
}

.no-md-left-space {
  margin-left: 0;
  padding-left: 0;
}

.no-md-right-space {
  margin-right: 0;
  padding-right: 0;
}

.p-left-10 {
  padding-left: 10px;
}

.p-left-15 {
  padding-left: 15px;
}

.p-right-15 {
  padding-right: 15px;
}

.more-info-text {
  text-decoration: underline;
  padding-top: 10px;

  a {
    color: $white;
  }
}

.eur {
  position: relative;

  > span {
    position: absolute;
    right: 16px;
    bottom: 10px;
    font-size: 16px;
    color: $primary-dark;
  }
}

.filters-page-address-bar {
  position: relative;
  min-height: 40px;

  .search-icon {
    position: absolute;
    top: 11px;
    left: 12px;
    z-index: 1;
    font-size: 14px;
    color: $primary-dark;
  }
}

.mat-slider-always-show-thumb {
  .mat-slider-thumb-label {
    transform: rotate(225deg) !important;
    border-radius: 50% 50% 0 !important;
  }

  .mat-slider-thumb {
    transform: scale(0) !important;
  }

  .mat-slider-thumb-label-text {
    opacity: 1 !important;
    transform: rotate(135deg) !important;
  }

  .mat-slider-thumb-label {
    top: 10px !important;
  }

  .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
    background-color: $brand-primary !important;
  }
}

.custom-autocomplete__dropdown {
  border-color: $grey-soft !important;
}

.custom-autocomplete__loader {
  box-shadow: 0 0 6px 0 black-opacity(0.23);
  top: -2px !important;
  height: calc(100% + 4px) !important;
}

.custom-height-24 {
  .datepicker-wrapper {
    height: 24px !important;
  }

  .datepicker-icon {
    bottom: 4px !important;
  }
}

.custom-height-32 {
  .datepicker-wrapper {
    height: 32px !important;
  }

  .datepicker-icon {
    bottom: 5px !important;
  }
}

.custom-height-37 {
  .datepicker-wrapper {
    height: 37px !important;
  }

  .datepicker-icon {
    bottom: 8px !important;
    font-size: 20px !important;
  }
}

.max-width-290 {
  max-width: 290px;
}

.max-width-400 {
  max-width: 400px;
}

.width-50 {
  width: 50%;
}

.clickable {
  cursor: pointer;
}

.non-clickable {
  cursor: default !important;
}

.primary-btn,
.secondary-btn {
  padding-left: 60px;
  padding-right: 60px;

  &.small-padding {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  &.extra-small-padding {
    padding-left: 24px;
    padding-right: 24px;
  }

  &.xxs-padding {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.full-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &:hover {
    text-decoration: none !important;
  }

  &.no-border {
    border: none;
  }

  &.ghost-btn {
    &.disabled {
      color: $button-disabled-text;
    }
  }
}

.primary-btn {
  &.ghost-btn {
    background: transparent;
    color: $brand-primary;
  }
}

.secondary-btn {
  border-radius: 28px;

  &.ghost-btn {
    background: transparent;
    color: $brand-secondary;
    border-color: $brand-secondary;

    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.desktop-only-element {
  display: block;
}

.desktop-only-element-inline-block {
  display: inline-block;
}

.mobile-only-element {
  display: none !important;
}

.offline-warning {
  display: flex;

  background-color: primary-warning-opacity(0.8);
  color: $white;
  padding: 16px;
  font-size: 14px;

  > i {
    font-size: 16px;
    margin-top: 2px;
    margin-right: 12px;
  }
}

.features-header {
  font-size: 24px;
  font-weight: 500;
  color: $primary-dark;
  margin-bottom: 24px;

  & + ul.features {
    margin-top: 0;
  }
}

ul.features {
  padding-left: 0;
  list-style: none;
  margin-top: 56px;

  > li {
    display: flex;
    align-items: center;

    > img {
      width: 32px;
      flex: 0 0 32px;
      height: auto;
    }

    > span {
      margin-left: 24px;
      font-size: 24px;
      color: $primary-dark;
    }
  }

  li + li {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .warning-message-card {
    font-size: 16px !important;
  }

  .no-md-left-space {
    margin-left: 0;
    padding-left: 0;

    margin-right: 0;
    padding-right: 0;
  }

  .no-md-right-space {
    margin-left: 0;
    padding-left: 0;

    margin-right: 0;
    padding-right: 0;
  }

  .mobile-no-side-space {
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
  }

  .driveddy-mat-checkbox {
    font-size: 16px;

    .mat-checkbox-inner-container {
      width: 16px;
      height: 16px;
    }
  }

  .driveddy-mat-radio-button {
    font-size: 16px;

    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      width: 16px;
      height: 16px;
    }
  }

  .mat-slide-toggle-content {
    white-space: normal !important;
  }

  .desktop-only-element {
    display: none !important;
  }

  .desktop-only-element-inline-block {
    display: none !important;
  }

  .mobile-only-element {
    display: block !important;
  }

  .features-header {
    font-size: 20px;
  }

  ul.features {
    li {
      align-items: flex-start;

      > img {
        padding-top: 7px;
      }
    }

    li > span {
      font-size: 20px;
      line-height: 1.55;
    }
  }
}

.success-rounded-btn {
  width: 340px;
  height: 45px;
  border-radius: 22.5px;
  box-shadow: 0 2px 8px 0 black-opacity(0.24);
  border: none;
  background-color: $brand-secondary;
  color: $white;
}

.white-rounded-btn,
a.white-rounded-btn {
  width: 191px;
  height: 45px;
  font-size: 16px;
  border-radius: 22.5px;
  box-shadow: 0 2px 8px 0 black-opacity(0.24);
  border: none;
  background-color: $white;
  cursor: pointer;
  color: $primary-dark !important;
}

.blue-cornerd-btn {
  min-width: 126px;
  min-height: 36px;
  margin: 0px;
  padding: 6px 23px 9px;
  border-radius: 5px;
  border: solid 1px $brand-primary;

  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  color: $brand-primary;
}

#zmmtg-root {
  display: none;
}

.ribbon {
  width: 147px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 3px solid $danger;
  filter: brightness(70%);
}

.ribbon span {
  position: absolute;
  display: block;
  width: 235px;
  padding: 15px 0;
  background-color: $danger;
  box-shadow: 0 5px 10px black-opacity(0.1);
  color: $white;
  text-shadow: 0 1px 1px black-opacity(0.2);
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
}

.ribbon.blue {
  &::before,
  &::after {
    border: 3px solid $brand-primary;
  }

  span {
    background-color: $brand-primary;
  }
}

.ribbon-top-right {
  top: -6px;
  right: -6px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.accounting-link-overlay {
  z-index: 999;
  width: 136px;
  border: solid 1px #ccc;
  border-radius: 5px;
  background: #fff;
  text-align: center;
  padding: 10px;
  margin: 0;

  & > li {
    list-style-type: none;
    cursor: pointer;
    border-bottom: solid 1px #8b8b8b;
    padding: 8px 0;

    &:hover {
      background-color: $body-bg;
    }
  }

  & > li:last-child {
    border-bottom: none;
  }
}

.page-container {
  margin-top: 40px;
}

.content-link {
  color: $brand-secondary;
  text-decoration: underline;
  cursor: pointer;
}

.text-link {
  color: $brand-primary;
  text-decoration: underline;
  cursor: pointer;

  &.text-link-red {
    color: $danger;
  }
}

.text-link-white {
  color: $white;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.online-theory-badge {
  position: fixed;
  bottom: 12px;
  left: 12px;
  z-index: 1000;

  display: flex;
  align-items: center;

  width: 392px;
  padding: 8px 15px 9px 9px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px var($grey-979797);

  font-size: 16px;
  line-height: 1.5;
  color: $primary-dark;

  > .badge-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 69px;
    height: 69px;
    margin-right: 14px;
    background-color: rgba($brand-secondary, 0.39);
    border-radius: 8px;

    font-size: 54px;
    font-weight: bold;
    text-align: center;
    color: $brand-secondary;
  }

  .badge-name {
    font-size: 18px;
    font-weight: 500;
  }
  .badge-time {
    font-size: 14px;
    font-weight: 500;
  }
  .badge-text {
    font-size: 12px;
    font-weight: 300;
  }

  @media only screen and (max-width: 359px) {
    display: none !important;
  }

  @media only screen and (max-width: 500px) {
    margin-left: -173px !important;
    width: 346px;
    padding: 7px 10px 8px 8px;

    > .badge-icon {
      flex: 0 0 60px;
      height: 60px;
      margin-right: 10px;

      font-size: 50px;
    }

    .badge-name {
      font-size: 17px;
    }
    .badge-time {
      font-size: 13px;
    }
    .badge-text {
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 768px) {
    left: 50%;
    margin-left: -196px;
  }
}

.hubspot-form {
  display: flex;
  flex-wrap: wrap;
  gap: 27px;

  .hs-form-field {
    flex: 1 0 48%;

    &.hs-firstname {
      order: 1;
    }
    &.hs-lastname {
      order: 2;
    }
    &.hs-email {
      order: 3;
    }
    &.hs-phone {
      order: 4;
    }
    &.hs_hast_du_schon_eine_praxisfahrschule_ {
      flex: 1 0 100%;
      order: 5;

      label {
        font-size: 16px;
        opacity: 1;
      }

      .hs-field-desc {
        margin-bottom: 8px;
        font-size: 13px;
        color: $primary-dark;
        opacity: 0.7;
      }

      .inputs-list.multi-container {
        padding-left: 5px;
        margin-bottom: 0;
      }

      .hs-form-radio {
        list-style: none;
        margin-top: 3px;
      }

      .hs-form-radio-display {
        display: flex;
        align-items: center;

        input {
          width: 18px;
          height: 18px;
          margin-right: 6px;
          cursor: pointer;
        }

        span {
          font-size: 16px;
        }
      }
    }

    label {
      margin-bottom: 0;
      font-size: 14px;
      color: $primary-dark;
      opacity: 0.7;
    }

    input {
      background: none;
      padding-top: 0.1em !important;
      padding-bottom: 0.3em !important;
      color: $primary-dark;
      font-size: 18px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      &:focus {
        outline: none;
        border-bottom: 1px solid $input-hl-color;
      }
      &:focus ~ label,
      &:valid ~ label {
        top: -14px;
        font-size: 12px;
        color: $input-hl-color;
      }
      &:focus ~ .bar:before {
        width: 100%;
      }
    }

    .hs-error-msgs {
      margin-top: 2px;
      margin-bottom: 0;
    }
  }

  .hs-submit {
    flex: 1 0 100%;
    order: 6;
    display: flex;
    justify-content: center;
    margin-top: 8px;

    .actions .hs-button {
      width: 206px;
      height: 48px;
      border-radius: 28px;
      border: none;
      background-color: $brand-primary;
      color: $white;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .hs_error_rollup {
    order: 6;
    .hs-error-msgs {
      margin-top: 10px;
      margin-bottom: 0;

      .hs-main-font-element {
        margin-bottom: 0;
        color: $primary-dark;
      }
    }
  }
}

// facebook loading
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #303f60;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

@media only screen and (max-width: 350px) {
  .small-margin-container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media only screen and (min-width: 351px) and (max-width: 500px) {
  .small-margin-container {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 768px) {
  .small-margin-container {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

@media only screen and (min-width: 650px) and (max-width: 767px) {
  .container {
    max-width: 614px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1194px) {
  .container {
    max-width: 1200px;
  }
}
