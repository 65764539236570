._form_32 ._form._inline-form._inline-style {
  padding: 0 !important;

  ._form-content {

    ._form_element._inline-style {
      ._field-wrapper {
        font-family: Roboto;
        width: 200px !important;

        > input {
          font-size: 16px !important;
          font-weight: normal;
          color: $primary-dark !important;
          width: 100% !important;
          border-radius: 30px !important;
          padding-left: 20px !important;

          &::placeholder {
            opacity: 0.7 !important;
          }
        }
      }
    }

    ._form-label {
      display: none !important;
    }

    ._button-wrapper {
      margin-top: 0 !important;

      #_form_32_submit {
        margin-top: 0;
        border-radius: 30px !important;
        font-size: 16px;
        font-weight: normal;
        color: $primary-dark !important;
        padding: 0 24px !important;
      }
    }
  }

  ._form-thank-you {
    color: $white !important;
    font-size: 20px !important;
    text-shadow: 1px 1px 1px black-opacity(.3) !important;
  }
}

@media only screen and (max-width: 768px) {
  ._form_32 ._form._inline-form._inline-style ._form-content {
    display: flex;
    flex-direction: column;

    ._field-wrapper {
      width: 100% !important;
    }
  }

  ._form_32 ._form._inline-form._inline-style {
    padding: 0 !important;

    ._form-content {

      ._form_element._inline-style {
        ._field-wrapper {
          width: 100% !important;
        }
      }
    }
  }
}
