@import 'colors';

.internal-page-card {
  position: relative;

  border-radius: 12px;
  box-shadow: 0 0 10px 0 black-opacity(0.25);
  background-color: $white;

  padding: 40px 25px 50px 25px;
}

.internal-page-title-container {
  margin-top: 40px;

  &.space-between {
    display: flex;
    justify-content: space-between;
  }
}

.internal-page-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.29;
  color: $primary-dark;
}

.internal-page-subtitle {
  font-size: 18px;
  line-height: 1.44;
  color: $primary-dark;
  font-weight: normal;

  margin-top: 24px;
  margin-bottom: 40px;
}

.internal-save-button {
  border-radius: 5px;
  background-color: $brand-primary;

  padding: 10px 30px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: $white;
  margin-top: 40px;
  float: right;
  border: none;

  &.disabled {
    background-color: $grey-disabled;
    color: $button-disabled-text;
  }
}

.school-internal-page {
  margin-left: 15px;
  margin-right: 15px;

  color: $primary-dark;

  .form-field-label {
    font-size: 20px;
    font-weight: bold;
    color: $primary-dark;

    margin-top: 35px;
    margin-bottom: 15px;
  }

  .form-text-field {
    font-size: 16px;
    color: $primary-dark;

    padding: 12px 16px;

    border-radius: 5px;
    border: solid 1px $grey-soft;
    background-color: $white;

    width: 100%;
  }

  .input {
    height: 45px;
    width: 100%;

    border-radius: 5px;
    border: solid 1px $grey-soft;

    background-color: $white;
    padding-left: 16px;

    font-size: 16px;
    color: $primary-dark;
  }
}

.primary-btn {
  border-radius: 5px;
  background-color: $brand-primary;
  padding: 10px 32px;

  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $white;
  border: none;

  &.no-background {
    background-color: transparent;
    color: $brand-primary;
  }

  &.cancel {
    background-color: $danger;
  }

  &.disabled {
    background-color: $grey-disabled;
    color: $button-disabled-text;
  }
}

.ghost-btn {
  border-radius: 5.1px;
  border: solid 1.3px $brand-primary;
  padding: 6px 26px;
  font-size: 16px;
  background-color: transparent;
  color: $brand-primary;
}

@media only screen and (min-width: 768px) {
  .internal-page-card {
    border-radius: 12px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .school-internal-page {
    margin-left: 45px;
    margin-right: 45px;
  }
}

@media only screen and (max-width: 768px) {
  .internal-page-title {
    font-size: 28px;
  }

  .internal-page-subtitle {
    margin-right: 16px;
    margin-left: 16px;
  }
}
