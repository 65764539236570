// sass-lint:disable no-color-hex
// basic colors
$grey-dark: #2d2d2d;
$grey-medium: #727171;
$grey-light: #b1b1b1;
$grey-very-light: #bbb;
$grey-soft: #e4e4e4;
$grey-96: #fafafa;
$grey-lightest: #e3e3e3;
$grey-e0e3e9: #e0e3e9;
$grey-979797: #979797;
$grey-838ca0: #838ca0;
$grey-b5bbc8: #b5bbc8;
$grey-eeeded: #eeeded;
$grey-c2c2c2: #c2c2c2;
$grey-f3f5f7: #f3f5f7;

$white: #fff;
$black: #000;
$orange: #f76900;
$gray: #666;
$shadow-red: #ad4b4b;
$coral-red: #dd6060;
$coral-red-light: #f09999;

// brand colors
$brand-primary: #2b8dd6;
$brand-primary-dark: #2271ac;
$brand-secondary: #27b8a1;
$brand-mix: #29a2bd;
$brand-primary-light: #59c3ff;
$brand-primary-opacity: #7ec7d5;

$brand-very-dark: #1f689d;
$brand-secondary-very-dark: #1c8877;

$brand-dark: #2270ab;
$brand-secondary-dark: #1f9380;

$brand-primary-light-1: #45abed;
$brand-primary-light-2: #59c3ff;
$brand-secondary-light: #2bd3b8;

$shadow-light-black: rgba(0, 0, 0, 0.1);
$shadow-middle-black: rgba(0, 0, 0, 0.25);

// interface colors
$body-bg: #f7f7f7;
$success: $brand-secondary;
$danger: #dd6060;
$pending: #ebdf49;
$grey-border: #ededed;
$gray-bbb: #bbb;
$grey-d8d8d8: #d8d8d8;
$grey-f8f8f8: #f8f8f8;
$grey-disabled: #f1f1f1;
$grey-fefefe: #fefefe;
$grey-f2f2f2: #f2f2f2;
$red: #f00;
$bright-orange: #e3d629;
$light-grayish-blue: #c6e4f3;
$blue: #1e739c;

$toast-success: #33c78f;
$toast-error: #f3755d;

$button-disabled: rgba(43, 181, 255, 0.53);
$button-disabled-text: #d9d9d9;

$widget-blue: #0c71c3;

$primary-dark: #303f60;
$secondary-dark: #606a80;
$primary-very-dark: #16283d;

$primary-grey: #e1e2e7;
$dark-grey: #282d30;

$primary-warning: #b4a024;

$light-green: #a1d863;
$success-green: #91cd4e;

$input-hl-color: #2196f3;

@function black-opacity($opacity) {
  @return rgba($black, $opacity);
}

//@function grey-dark-opacity($opacity) {
//  @return rgba($grey-dark, $opacity);
//}

@function primary-dark-opacity($opacity) {
  @return rgba($primary-dark, $opacity);
}

@function white-opacity($opacity) {
  @return rgba($white, $opacity);
}

@function brand-primary-opacity($opacity) {
  @return rgba($brand-primary, $opacity);
}

@function brand-secondary-opacity($opacity) {
  @return rgba($brand-secondary, $opacity);
}

@function primary-warning-opacity($opacity) {
  @return rgba($primary-warning, $opacity);
}

@function color-with-opacity($color, $opacity) {
  @return rgba($color, $opacity);
}

// Calendar Colors

$cal-practice: #e1c32d;
$cal-theory: $brand-primary;
$cal-practice-green: #06b87c;
$cal-today: #565656;
$cal-theory-progress-bar: #97bbed;
$cal-theory-event: #4e72da;
$cal-payment-status: rgba(1, 1, 1, 0.14);
$cal-practice-unpaid-color1: #50b97c;
$cal-practice-unpaid-color2: #93dcb1;
$cal-practice-unpaid-color3: #50b97c;
$cal-practice-free-slot-color1: #d6f7ec;
$cal-waiting-for-learner-bg: #f2d63d;
$cal-practice-exams-bg: $danger;
$cal-not-occurred-bg: #ff9a45;
$cal-exam-bg: #f28b3d;
$cal-block-time-color1: #8e8c8c;
$cal-block-time-color2: #cecece;
$cal-block-time-color3: #afaead;
$cal-block-time-color4: #dedede;
$cal-free-block-color1: #4dcaa0;
$cal-payment-status: rgba(1, 1, 1, 0.14);
$cal-legends-theory-color: #52a3db;
$cal-legends-requests-color1: #f3d94f;
$cal-legends-requests-color2: #f2f9c2;
$cal-control-shadow: rgba(0, 0, 0, 0.24);
$cal-border: #e1e1e1;
$cal-black: #2c4063;

$whatsapp-color: #8dd5a0;

$ratings-yellow: #f0cb37;

$ratings-secondary-yellow: #f2d95c;

$feedback-grey: rgba(144, 154, 175, 0.6);

$card-field-green: rgba(102, 216, 198, 0.16);

$progress-bar-1: #b4ec51;
$progress-bar-2: #f5ed45;
$progress-bar-3: #ffbb48;
$progress-bar-4: #f00;

$rating-eddyclub-1: #e3515a;
$rating-eddyclub-2: #efbc4c;
$rating-eddyclub-3: #e6f146;
$rating-eddyclub-4: #d9fc7a;
$rating-eddyclub-5: #a7f86a;
$rating-eddyclub-6: #71e340;

$chat-message: #f2f3f4;
$chat-own-message: #eef8ff;

$on-time-notification: #e3f2ff;
$late-notification: #fbeded;

$youtube: #c4302b;
$facebook: #3b5998;
$instagram1: #f09433;
$instagram2: #e6683c;
$instagram3: #dc2743;
$instagram4: #cc2366;
$instagram5: #bc1888;
