@import 'colors';
@import '~ngx-toastr/toastr.css';

.toast-container .ngx-toastr {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 65px;
  min-width: 320px;

  box-shadow: 0 2px 7px 0 black-opacity(.24);
}

.toast-success, .toast-error {
  background-image: none !important;

  &::before {
    font-family: "Font Awesome 5 Pro";
    font-size: 24px;
    font-weight: 900;
    color: $white;
    position: absolute;
    left: 25px;
    margin-top: -4px;
  }
}

.toast-success {
  background-color: $toast-success;

  &::before {
    content: '\f058';
  }
}

.toast-error {
  background-color: $toast-error;

  &::before {
    content: '\f057';
  }
}

.toast-message {
  font-size: 18px;
  font-weight: 400;
}

@media only screen and (min-width: 768px) {
  .toast-error {
    min-width: 430px;
  }

  .toast-container {
    right: 50px;
  }
}
