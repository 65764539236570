@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?alq0jk');
  src:  url('fonts/icomoon.eot?alq0jk#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?alq0jk') format('truetype'),
    url('fonts/icomoon.woff?alq0jk') format('woff'),
    url('fonts/icomoon.svg?alq0jk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-class-be:before {
  content: "\e900";
}
.icon-class-am:before {
  content: "\e901";
}
.icon-class-a2:before {
  content: "\e902";
}
.icon-class-b:before {
  content: "\e903";
}
.icon-class-a1:before {
  content: "\e904";
}
.icon-class-a:before {
  content: "\e905";
}
