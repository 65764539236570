@import 'colors';

.info-page {
  .panel {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .main-title {
    font-size: 32px;
    font-weight: 500;
    color: $primary-dark;

    margin-top: 20px;
    margin-bottom: 10px;
  }

  .main-subtitle {
    font-size: 16px;
    line-height: 1.5;
    color: $primary-dark;

    margin-top: 10px;
    margin-bottom: 40px;
  }

  .panel-title {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: -0.4px;

    line-height: 1.3;

    margin-bottom: 40px;

    color: $primary-dark;
  }

  .panel-subtitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.08;
    color: $primary-dark;
  }

  .panel-text {
    font-size: 14px;
    line-height: 1.56;
    color: $primary-dark;
  }

  .panel-text + .panel-text {
    margin-top: 25px;
  }

  .text-block {
    margin-top: 50px;
  }

  .text-align-right {
    text-align: right;
  }

  .white-bg {
    background-color: $white;
  }

  .fa-dot-circle {
    margin-right: 10px;
  }

  .blue {
    color: $brand-primary;
  }

  li {
    margin-bottom: 10px;
    display: flex;

    > i {
      padding-top: 3px;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  .dotted-items-list {
    font-size: 14px;
  }

  .dotted-items-list > li > span {
    color: $primary-dark;
  }

  .ghost-btn {
    height: 45px;
    border-radius: 2px;

    padding-left: 18px;
    padding-right: 18px;

    background-color: transparent;

    cursor: pointer;
  }

  .ghost-btn + .ghost-btn {
    margin-top: 18px;
  }

  .fill-btn {
    height: 40px;

    padding-left: 18px;
    padding-right: 18px;

    border-radius: 2px;

    font-size: 16px;
    font-weight: 500;

    cursor: pointer;
  }

  .blue-fill {
    color: $white;
    border: none;
    background-color: $brand-primary;
  }

  .blue-btn {
    color: $brand-primary;
    border: 1px solid $brand-primary;
  }

  .grey-dark {
    color: $primary-dark;
  }

  .img-label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img-label {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: $primary-dark;

    margin-top: 15px;
    margin-bottom: 30px;
  }

  a:hover {
    text-decoration: none;
  }

  h3 {
    margin-bottom: 0;
  }

  h2 + h3, p + h3 {
    margin-top: 20px;
  }

  h3 + p {
    margin-top: 10px;
  }

  h3 + ul {
    margin-top: 25px;
  }

  @media only screen and (min-width: 768px) {
    .main-title {
      font-size: 42px;
    }

    .main-subtitle {
      font-size: 20px;
    }

    .panel-title {
      font-size: 34px;
    }

    .panel-subtitle {
      font-size: 26px;
    }

    h2 + h3, p + h3 {
      margin-top: 40px;
    }

    h3 + p {
      margin-top: 20px;
    }

    .ghost-btn + .ghost-btn {
      margin-top: 0;
      margin-left: 18px;
    }

    .img-label {
      margin-top: 30px;
      margin-bottom: 0;
    }

    .panel-text {
      font-size: 18px;
    }

    .dotted-items-list {
      font-size: 18px;
    }

    li {
      margin-bottom: 20px;
    }
  }
}
