@import 'colors';

.signup-page {
  display: flex;
  width: 1024px;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  background: $white;
  color: $primary-dark;
  box-shadow: 0 0 12px color-with-opacity($black, 0.25);

  .mat-form-field-label {
    /*change color of label*/
    color: $primary-dark !important;
  }

  .mat-form-field-underline {
    /*change color of underline*/
    background-color: $primary-dark !important;
  }

  .mat-form-field-ripple {
    /*change color of underline when focused*/
    background-color: $primary-dark !important;
  }

  .mat-radio-outer-circle {
    background: $grey-border;
    border-color: $grey-border;
  }

  .mat-radio-checked label div.mat-radio-outer-circle {
    background: $white !important;
  }

  $radio-checkbox-size: 40px;

  .mat-radio-container {
    width: $radio-checkbox-size;
    height: $radio-checkbox-size;
  }

  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    width: $radio-checkbox-size;
    height: $radio-checkbox-size;
  }

  .mat-checkbox label div.mat-checkbox-inner-container {
    width: $radio-checkbox-size;
    height: $radio-checkbox-size;
    background: $grey-border;
    border-color: transparent;
    border-radius: 3px;

    .mat-checkbox-frame {
      border-width: 0;
    }
  }

  h2 {
    font-size: 35px;
    font-weight: 300;
    margin-top: 16px;
    margin-bottom: 32px;

    strong,
    b {
      font-weight: bold;
    }
  }

  .mat-checkbox-label {
    margin-left: 10px;
    margin-top: 7px;

    .mat-checkbox-label {
      line-height: 1.42;
    }
  }

  .wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    padding: 35px 69px 32px 69px;

    .flex-grow {
      flex-grow: 1;
    }
  }

  .checkbox,
  .radio-button {
    height: 40px;
  }

  .checkbox + .checkbox,
  .radio-button + .radio-button {
    margin-top: 40px;
  }
}

.signup-page.welcome {
  background: url('/assets/signup-wizard/signup-form-cover.png') !important;
  background-size: cover !important;
  max-height: 100%;
}

@media only screen and (max-width: 768px) {
  .signup-page {
    width: 100%;

    h2 {
      font-size: 26px;
      line-height: 1.31;
    }

    .wrapper {
      padding: 35px 16px 32px 16px;
    }

    .question {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.4;
      color: $primary-dark;
    }

    .radio-button {
      font-size: 18px !important;
    }

    $radio-checkbox-size: 32px;

    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle,
    .mat-checkbox label div.mat-checkbox-inner-container {
      width: $radio-checkbox-size !important;
      height: $radio-checkbox-size !important;
    }

    .checkbox,
    .radio-button {
      height: $radio-checkbox-size !important;
    }

    .checkbox + .checkbox,
    .radio-button + .radio-button {
      margin-top: $radio-checkbox-size !important;
    }

    .right-side-radio-buttons {
      margin-top: $radio-checkbox-size;
    }

    .field {
      font-size: 18px !important;

      mat-form-field {
        width: 100% !important;
      }
    }

    .yes-no-container {
      display: flex;
      flex-direction: column;

      .yes-btn {
        margin-left: 0;
      }
    }
  }
}
