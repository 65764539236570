@import 'colors';
@import 'variables';

.driveddy-table-wrapper {
  border-radius: 12px;
  box-shadow: 0 0 16px 0 black-opacity(0.1), 0 0 4px 0 black-opacity(0.1);
  background-color: $white;
  margin-bottom: 56px;
}

mat-table.driveddy-table {
  background-color: transparent;
}

.driveddy-table {
  mat-footer-row,
  mat-header-row,
  mat-row {
    background-color: transparent;
    border: none;
    margin-bottom: 2px;
  }

  mat-header-row {
    background-color: color-with-opacity($primary-dark, 0.05);
  }

  mat-row:hover {
    border-radius: 2px;
    box-shadow: 0 0 2px 0 black-opacity(0.24);
    background-color: $white;
  }

  &.selectable-rows {
    mat-row {
      cursor: pointer;
    }
  }

  .mat-sort-header-button,
  .mat-header-cell {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.89px;
    opacity: 0.7;
    color: $primary-dark;
  }

  &.uppercase-headers {
    .mat-sort-header-button,
    .mat-header-cell {
      text-transform: uppercase;
    }
  }

  .mat-cell {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $primary-dark;
  }

  .mat-checkbox-layout {
    margin-bottom: 0;
  }

  .mat-checkbox-inner-container {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .mat-checkbox-inner-container-no-side-margin {
    margin-left: 0;
    margin-right: 0;
  }

  &.transparent {
    background-color: transparent;

    mat-header-row,
    mat-row {
      border-bottom: 1px solid $grey-soft;
    }

    mat-footer-row,
    mat-header-row,
    mat-row {
      background-color: transparent;
    }

    mat-row:hover {
      border-radius: 0;
      box-shadow: none;
      background-color: $body-bg;
    }
  }
}

mat-paginator.driveddy-paginator {
  background-color: transparent;
}

.driveddy-paginator {
  .mat-paginator-page-size-label {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: $grey-medium;
  }

  .mat-paginator-page-size-select {
    margin: 6px 4px 0 7px;
    width: 50px;
  }

  .mat-select-value-text {
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $primary-dark;
  }

  .mat-form-field-underline {
    color: $grey-very-light;
  }

  .mat-select-arrow {
    border: none;
  }

  .mat-select-arrow::before {
    content: '\f078';
    font-family: 'Font Awesome\ 5 Pro';
    bottom: 6px;
    position: relative;
    right: 10px;
    font-size: 14px;
  }

  .mat-paginator-range-label {
    margin: 0 20px 0 26px;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $grey-medium;
  }

  .mat-paginator-navigation-previous,
  .mat-paginator-navigation-next {
    border: none;
    position: relative;
  }

  .mat-paginator-navigation-previous::before,
  .mat-paginator-navigation-next::before {
    font-family: 'Font Awesome\ 5 Pro';
    bottom: 1px;
    font-size: 14px;
    position: absolute;
  }

  .mat-paginator-navigation-previous::before {
    left: 14px;
  }

  .mat-paginator-navigation-next::before {
    left: 18px;
  }

  .mat-button-wrapper,
  .mat-button-ripple {
    display: none;
  }

  .mat-paginator-navigation-previous:hover,
  .mat-paginator-navigation-next:hover {
    background-color: transparent;
  }

  .mat-paginator-navigation-previous::before {
    content: '\f053';
  }

  .mat-paginator-navigation-next::before {
    content: '\f054';
  }
}

.table-actions-container {
  min-height: 65px;
  border-radius: 2px;
  margin-top: 60px;
  width: 100%;
  padding: 10px 15px 8px;
  background-color: transparent;
}

.table-actions-container.small-top-margin {
  margin-top: 35px;
}

.driveddy-table-top-filters-container {
  margin-top: 55px;
}

.table-actions-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.table-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.table-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 30%;

  height: 30px;

  padding-left: 18px;
  padding-right: 18px;

  font-size: 16px;
  text-align: center;
  color: $grey-medium;
  cursor: pointer;

  border-radius: 15px;
}

.table-filter + .table-filter {
  margin-top: 8px;
}

.table-filter.active {
  background-color: $brand-primary;
  color: $white;
}

.filter-dropdown {
  min-width: 200px;
  max-width: 200px;
  & + .filter-dropdown {
    margin-top: 8px;
    margin-left: 0;
  }

  &.filter-dropdown-183 {
    min-width: 183px;
    max-width: 183px;
  }

  &.filter-dropdown-172 {
    min-width: 172px;
    max-width: 172px;
  }

  &.filter-dropdown-163 {
    min-width: 163px;
    max-width: 163px;
  }

  &.filter-dropdown-140 {
    min-width: 140px;
    max-width: 140px;
  }
}

.table-action + .table-action {
  margin-top: 8px;
}

.table-header-btn {
  border-radius: 20px;
  background-color: $brand-primary;
  color: $white;
  border: none;
  cursor: pointer;
  white-space: nowrap;

  padding: 8px 20px;

  > i {
    margin-right: 5px;
  }
}

.table-header-add-btn {
  padding: 8px 15px;
  border-radius: 50%;
  background-color: $brand-primary;
  color: $white;
  border: none;
  cursor: pointer;

  > i {
    margin: 0;
  }
}

.table-action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  flex-grow: 1;

  margin-top: 15px;

  button + button {
    margin-top: 8px;
  }
}

.action-box {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  border-radius: 3px;
  border: solid 1px $grey-soft;
  background-color: $white;
  cursor: pointer;

  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  overflow: visible;

  > i {
    font-size: 18px;
    font-weight: 300;
    color: $brand-primary;
  }

  & + .action-box {
    margin-left: 16px;
  }
}

.ellipsis-field {
  text-align: right;

  &.ellipsis-mobile {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.table-actions-button {
  color: #2b78b1;
  font-size: 16px;
}

.mat-icon-button[disabled] {
  background-color: unset;
  border: none;
  .table-actions-button {
    color: $button-disabled-text;
    font-size: 16px;
  }
}

.table-mat-menu-panel.mat-menu-panel {
  min-height: 48px;
  border-radius: 12px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.1);
  .mat-menu-content:not(:empty) {
    padding: 0;
    .menu-btn {
      font-size: 16px;
      color: $primary-dark;

      &:not(:last-child) {
        border-bottom: 1px solid $grey-e0e3e9;
      }

      &.disabled {
        color: $grey-disabled;

        i {
          color: $grey-disabled;
        }
      }

      i {
        margin-right: 15px;
        font-size: 18px;
        color: #2b78b1;
      }
    }
  }
}

.no-entries {
  height: 261px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .no-entries-found {
    padding: 5px 18px;
    opacity: 0.25;
    border-radius: 15px;
    background-color: $primary-dark;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: $white;
  }

  .remove-filter-cont {
    margin-top: 18px;
    color: $primary-dark;

    .text-link {
      margin-left: 2px;
    }
  }
}

@media only screen and (min-width: $mobile-table-breakpoint-min) {
  .table-scrollable {
    display: block;
    width: 100%;
    overflow-x: auto;

    &.loading {
      min-height: 178px;
    }

    .mat-table {
      width: 100%;
      max-width: 100%;
      margin: 0 0 1px 0;
      display: table;
      border-collapse: collapse;
    }

    .mat-table-sticky:last-child {
      &.mat-header-cell,
      &.mat-cell {
        min-width: 100px;
        width: 100px;
        text-align: center;
      }
      &.mat-header-cell {
        background-color: #f4f5f7;
        opacity: 1;
      }
      &.mat-cell {
        background-color: $white;

        .d-flex {
          justify-content: center;
        }
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        border-left: 1px solid #e0e0e0;
        display: block;
        top: 1px;
      }
    }

    .mat-row,
    .mat-header-row {
      display: table-row;
    }

    .mat-cell,
    .mat-header-cell {
      word-wrap: initial;
      display: table-cell;
      padding: 0px 10px;
      line-break: unset;
      width: auto;
      overflow: hidden;
      vertical-align: middle;
    }

    .mat-cell:first-of-type,
    .mat-header-cell:first-of-type,
    .mat-footer-cell:first-of-type {
      padding-left: 24px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #f5f6f8;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-image: linear-gradient(to right, #8c8f94 12%, #b0b2b5 88%);
    }
  }

  .ellipsis-field {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .dashboard-table {
    .driveddy-table {
      mat-header-row {
        background-color: transparent;
        min-height: 24px;
        border-bottom: 1px solid $grey-e0e3e9;
      }
      mat-row {
        min-height: 36px;
        border-bottom: 1px solid $grey-e0e3e9;
      }

      .mat-header-cell {
        font-size: 13px;
        opacity: 1;
      }

      .mat-column-action .mat-icon-button {
        font-size: 16px;
      }
    }

    &.table-scrollable .mat-table-sticky:last-child.mat-header-cell {
      background-color: transparent;
    }

    &.table-scrollable .mat-table-sticky:last-child.mat-header-cell,
    &.table-scrollable .mat-table-sticky:last-child.mat-cell {
      min-width: 76px;
      width: 76px;
    }

    &.table-scrollable .mat-table-sticky:last-child::before {
      border: none;
    }
  }
}

@media only screen and (max-width: $mobile-table-breakpoint-max) {
  .filter-dropdown {
    max-width: 100% !important;
    width: 100%;
    .custom-height-40.ng-select.ng-select-single {
      max-width: 100% !important;
      .ng-select-container {
        max-width: 100% !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .driveddy-table {
    mat-footer-row,
    mat-header-row,
    mat-row {
      min-height: 48px;
    }

    &.transparent {
      mat-footer-row,
      mat-header-row,
      mat-row {
        min-height: 58px;
      }
    }

    &.theory-table {
      mat-footer-row,
      mat-header-row,
      mat-row {
        min-height: 90px;
      }
    }
  }

  .table-actions-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 8px;
    column-gap: 30px;

    &.second-row-filters {
      justify-content: flex-start;
      margin-top: 0 !important;
    }

    .table-action-buttons {
      margin-left: auto;
    }

    .table-filter-container {
      margin-right: auto;
    }
  }

  .table-filter-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
  }

  .table-filter {
    flex: unset;

    margin-top: 0;
    margin-left: 5px;

    & + .table-filter {
      margin-top: 0;
    }
  }

  .filter-dropdown {
    max-width: 100%;
    margin-top: 0 !important;

    & + .filter-dropdown {
      margin-top: 0;
      margin-left: 5px;
    }
  }

  .table-action {
    margin-top: 0;
    margin-left: 8px;

    & + .table-action {
      margin-top: 0;
    }
  }

  .table-action-buttons {
    flex-direction: row;
    margin-top: 0;

    button + button {
      margin-top: 0;
    }
  }
}
