@import 'colors';

.driveddy-mat-select {
  .select-container {
    height: 77px;
    border-radius: 2px;
    box-shadow: 0 0 8px 0 black-opacity(.25);
    background-color: $white;
    width: 100%;

    mat-form-field {
      width: 100%;
      height: 100%;

      .mat-form-field-wrapper {
        padding: 0;
        height: 100%;

        .mat-form-field-flex {
          height: 100%;

          .mat-form-field-infix {
            padding: 15px;
            border: none;
            height: 100%;

            .mat-select {
              border: 1px solid $grey-soft;
              height: 100%;

              .mat-select-arrow {
                visibility: hidden;
              }

              &::after {
                font-family: "Font Awesome 5 Pro";
                content: '\f002';
                font-size: 16px;
                font-weight: 300;
                text-align: right;
                color: $brand-primary;

                position: absolute;
                right: 40px;
                top: 30px;
              }

              .mat-select-value-text {
                position: absolute;
                margin-top: 10px;
                margin-left: 10px;
              }
            }

            .mat-form-field-label-wrapper {
              margin-top: 23px;
              margin-left: 30px;
            }
          }
        }
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }
}

.mat-select-panel {
  max-width: 100% !important;
}

.mat-select-content {
  width: 2000px !important;
}
