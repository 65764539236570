/* buttons */

.btn { text-shadow: none;
  >i, >svg, >.btn-icon {
    margin-right:10px;
    width:24px !important;
  }
}

.btn-block {
  @include flexbox;
  @include align-items(center);
}

.btn-grey-light {
  color:$grey-light;
}


.btn-sm {
  font-size:12px;
}

.btn-lg {
  font-size:18px;
  font-weight:300;
  text-transform: uppercase;
}


.btn-primary,
.btn-success,
.btn-warning,
.btn-danger {
  background-image:none !important;
  border:0px solid transparent !important;
  border-radius:2px;
}
